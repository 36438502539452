import { Option } from '@glow/ui-components'
import { Formik, FormikHelpers } from 'formik'
import i18next from 'i18next'
import { List, Map, OrderedSet, Set } from 'immutable'
import { DateTime } from 'luxon'
import React, { ReactNode, useContext, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { dispatchManualOverrideEvent } from '../../../components/newShipmentModal/panels/ManualOverrideUtils'
import { ErrorResponseToast, ToastContext } from '../../../contexts/ToastContext'
import { useAppDispatch } from '../../../reducers/redux-hooks'
import { Consignment, OrderIdType } from '../../../types/coreEntitiesTypes'
import { ImmutableMap } from '../../../types/immutableTypes'
import { EventType } from '../../../utils/consignmentEvent'
import { ConsignmentState, consignmentCanBeCollected } from '../../../utils/consignmentState'
import { today } from '../../../utils/dateTime'
import { DeviationCodeLabel, pickupDeviationCodes } from '../../../utils/deviation'
import { TIMESTAMP_DATE_FORMAT, TIMESTAMP_TIME_FORMAT } from '../../../utils/inputValidation'

export interface ManualOverrideFormProps {
  packages: string[]
  event: EventType | ''
  name: string
  date: string
  time: string
  departmentId: string
  deviationCode: string
  unitId: string
  driverUserId: string
}

const initialFormValues = (
  defaultPackages: Option[],
  canCollectShipment: boolean,
  departmentId: string,
  consignments: List<PartialConsignmentMap>,
  setNoInitialEventType?: boolean
): ManualOverrideFormProps => {
  const consignmentIds = defaultPackages.map((x) => x.code)
  const selectedConsignments = consignments.filter((c) => consignmentIds.includes(c.get('id') + ''))

  const firstUnitId = selectedConsignments.first(undefined)?.get('courierId')
  const allConsignmentsHaveSameUnit = selectedConsignments.every((c) => c.get('courierId') === firstUnitId)
  const selectedUnitId = allConsignmentsHaveSameUnit && firstUnitId ? firstUnitId.toString() : ''

  const firstDriverUserId = selectedConsignments.first(undefined)?.get('driverUserId')
  const allConsignmentsHaveSameDriver = selectedConsignments.every((c) => c.get('driverUserId') === firstUnitId)
  const selectedDriverUserId = allConsignmentsHaveSameDriver && firstDriverUserId ? firstDriverUserId.toString() : ''

  return {
    packages: defaultPackages.map((x) => x.code + ''),
    event: setNoInitialEventType ? '' : canCollectShipment ? EventType.COLLECTED : EventType.DELIVERED,
    name: '',
    date: today().toFormat('yyyy-MM-dd'),
    time: DateTime.local().toFormat('HH:mm'),
    departmentId,
    deviationCode: pickupDeviationCodes.first<DeviationCodeLabel>(Map()).get('code'),
    unitId: selectedUnitId,
    driverUserId: selectedDriverUserId
  }
}

interface Props {
  consignments: List<Consignment>
  orderIds: List<OrderIdType>
  urlDepartmentId: string
  url: string
  bulkActionMode?: boolean
}

export interface FormikRenderProps {
  values: ManualOverrideFormProps
  isSubmitting: boolean
  setFieldValue: (key: string, value: string) => void
  packageOptions: Option[]
}

export interface PartialConsignment {
  id: number
  state: ConsignmentState
  packageId: string
  courierId?: number
  driverUserId?: number
  vasCodes?: List<string>
  orderId: number
  type: string | null
}

export interface PartialConsignmentMap extends ImmutableMap<PartialConsignment> {}

export interface FormWrapperProps extends Omit<Props, 'consignments'> {
  children: (props: FormikRenderProps) => ReactNode
  consignments: List<PartialConsignmentMap>
  onClose?: () => void
  setNoInitialEventType?: boolean
}

export const FormWrapper = ({
  children,
  consignments,
  bulkActionMode,
  urlDepartmentId,
  orderIds,
  onClose,
  setNoInitialEventType
}: FormWrapperProps) => {
  const { setToast, setToastError } = useContext(ToastContext)
  const dispatch = useAppDispatch()
  // For unknown reason, formik isSubmitting doesn't work with the RouteLeavingGuard when saving
  const [isSubmitting, setIsSubmitting] = useState(false)
  const navigate = useNavigate()

  const packageOptions: Option[] = useMemo(() => {
    return consignments
      .map((consignment) => ({ code: `${consignment.get('id')}`, label: consignment.get('packageId') }))
      .toArray()
  }, [consignments])

  const canCollectShipment = consignments.every((consignment) =>
    consignmentCanBeCollected.contains(consignment.get('state'))
  )

  const onEventSubmit = async (
    values: ManualOverrideFormProps,
    { resetForm }: FormikHelpers<ManualOverrideFormProps>
  ) => {
    if (eventTimeIsInFuture(values.date, values.time)) {
      setToast({ variant: 'error-dark', text: i18next.t('shipmentDetails.eventTimeCannotBeInFuture') })
      return
    }
    setIsSubmitting(true)
    const safeNumbers = values.packages.map((x) => Number(x)).filter((x) => !isNaN(x))
    try {
      await postEvents(safeNumbers, values)
      onClose?.()
      if (values.event !== EventType.SCANNED) {
        setToast({ variant: 'success-dark', text: i18next.t('shipmentDetails.eventWasAdded') })
      }
      resetForm()
      if (bulkActionMode && !setNoInitialEventType) {
        navigate(-1)
      }
    } catch (error) {
      setToastError(error as ErrorResponseToast)
    } finally {
      setIsSubmitting(false)
    }
  }

  const eventTimeIsInFuture = (date: string, time: string): boolean => {
    const eventTime = DateTime.fromFormat(`${date} ${time}`, `${TIMESTAMP_DATE_FORMAT} ${TIMESTAMP_TIME_FORMAT}`)
    return eventTime.toISO() > DateTime.now().toISO()
  }

  const postEvents = async (safeNumbers: number[], values: ManualOverrideFormProps) => {
    return new Promise(async (resolve, reject) => {
      const timestamp = DateTime.fromFormat(
        `${values.date} ${values.time}`,
        `${TIMESTAMP_DATE_FORMAT} ${TIMESTAMP_TIME_FORMAT}`
      ).toISO()
      if (safeNumbers.length > 0) {
        const formValues = { ...values, consignmentIds: Set.of(...safeNumbers) }
        const req = dispatchManualOverrideEvent(
          values.event as EventType,
          OrderedSet(orderIds),
          timestamp,
          formValues,
          dispatch
        )

        if (req) {
          try {
            await req
            return resolve({})
          } catch (error) {
            reject(error)
          }
        } else {
          reject(i18next.t('shipmentDetails.issueSaving'))
        }
      } else {
        reject(i18next.t('shipmentDetails.noSelectedPackages'))
      }
    })
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialFormValues(
        packageOptions,
        canCollectShipment,
        urlDepartmentId,
        consignments,
        setNoInitialEventType
      )}
      onSubmit={(values, helpers) => onEventSubmit(values, helpers)}
    >
      {({ values, setFieldValue }) => (
        <>
          {/* <RouteLeavingGuard when={dirty && !isSubmitting} /> */}
          {children({ values, isSubmitting, setFieldValue, packageOptions })}
        </>
      )}
    </Formik>
  )
}
