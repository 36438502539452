import { List, Map } from 'immutable'
import { ImmutableMap } from '../types/immutableTypes'
import { CountryCode, isDemo, isAmoi, isPilot, isIca, isMiist, isNordiskaGalleriet } from '@glow/common'
import i18next from 'i18next'

interface ICountryProps {
  code: string
  name: string
}

export interface ICountry extends ImmutableMap<ICountryProps> {}

export const getSupportedCountries = () => {
  if (isDemo()) {
    return demoCountries
  } else if (isAmoi()) {
    return amoiCountries
  } else if (isPilot()) {
    return pilotCountries
  } else if (isIca()) {
    return icaCountries
  } else if (isMiist()) {
    return miistCountries
  } else if (isNordiskaGalleriet()) {
    return nordiskaGallerietCountries
  } else {
    return bringCountries
  }
}

export enum Country {
  NO = 'Norway',
  SE = 'Sweden',
  FI = 'Finland',
  DK = 'Denmark',
  BE = 'Belgium',
  FR = 'France',
  GB = 'United Kingdom',
  SI = 'Slovenia'
}

function country(cc: CountryCode) {
  return Map({ code: cc, name: i18next.t('application.country.' + cc) })
}

const countries = List<ICountry>([
  country(CountryCode.AD),
  country(CountryCode.AT),
  country(CountryCode.BE),
  country(CountryCode.BA),
  country(CountryCode.BG),
  country(CountryCode.HR),
  country(CountryCode.CZ),
  country(CountryCode.DK),
  country(CountryCode.GB),
  country(CountryCode.EE),
  country(CountryCode.FO),
  country(CountryCode.FI),
  country(CountryCode.FR),
  country(CountryCode.DE),
  country(CountryCode.GI),
  country(CountryCode.GR),
  country(CountryCode.HU),
  country(CountryCode.IS),
  country(CountryCode.IE),
  country(CountryCode.IT),
  country(CountryCode.LV),
  country(CountryCode.LT),
  country(CountryCode.LU),
  country(CountryCode.MK),
  country(CountryCode.MT),
  country(CountryCode.MC),
  country(CountryCode.ME),
  country(CountryCode.NL),
  country(CountryCode.NO),
  country(CountryCode.PL),
  country(CountryCode.PT),
  country(CountryCode.RO),
  country(CountryCode.RU),
  country(CountryCode.SM),
  country(CountryCode.RS),
  country(CountryCode.SK),
  country(CountryCode.SI),
  country(CountryCode.ES),
  country(CountryCode.SE),
  country(CountryCode.CH),
  country(CountryCode.UA)
])

const bringCountries = countries.filter((country) =>
  new Set([CountryCode.NO, CountryCode.FI, CountryCode.DK, CountryCode.SE, CountryCode.DE, CountryCode.EE]).has(
    country.get('code') as CountryCode
  )
)
const amoiCountries = countries.filter((country) =>
  new Set([CountryCode.NO, CountryCode.SE]).has(country.get('code') as CountryCode)
)

const demoCountries = countries

// Denmark
const pilotCountries = countries.filter((country) => new Set([CountryCode.DK]).has(country.get('code') as CountryCode))

const icaCountries = countries.filter((country) => new Set([CountryCode.SE]).has(country.get('code') as CountryCode))

const miistCountries = countries.filter((country) => new Set([CountryCode.FR]).has(country.get('code') as CountryCode))

const nordiskaGallerietCountries = countries.filter((country) =>
  new Set([CountryCode.SE]).has(country.get('code') as CountryCode)
)

export const getCountryNameFromCountryCode = (countryCode: string | undefined | null): string | undefined =>
  getSupportedCountries()
    .find((country) => country.get('code').toLowerCase() === countryCode?.toLowerCase())
    ?.get('name')
