import { ButtonBase, LayoutForm, ModalBase, ModalBaseButtons } from '@glow/ui-components'
import i18next from 'i18next'
import { List } from 'immutable'
import { Form } from 'formik'
import { useLocation, useParams } from 'react-router-dom'
import { Shipment, OrderIdType, isHDOrder, isH2Order } from '../../../types/coreEntitiesTypes'
import React, { ReactNode, useContext, useMemo } from 'react'
import { FormikRenderProps, FormWrapper, FormWrapperProps, PartialConsignmentMap } from './FormWrapper'
import { EventHeading, eventSelectOptions, mapToPartialConsignmentMap } from './Utils'
import { FormikMultiSelect, FormikSelect } from '@glow/formik-components'
import { requiredFormik } from '../../../utils/formikInputValidation'
import { EventType } from '../../../utils/consignmentEvent'
import { FormFieldsByEvent } from './Forms'
import { consignmentCanBeCollected, ConsignmentState } from '../../../utils/consignmentState'
import { usePartialShipmentData } from '../bulk-actions/useSelectedOrderIdsFromUrl'
import { ToastContext } from '../../../contexts/ToastContext'

export const ManualOverrideBulkEventsModal = ({
  open,
  onClose,
  selectedOrderIds
}: {
  open: boolean
  onClose: () => void
  selectedOrderIds: OrderIdType[]
}) => {
  const location = useLocation()
  const params = useParams()
  const { setToast } = useContext(ToastContext)
  const [partialConsignments] = usePartialShipmentData(selectedOrderIds)

  const consignments = useMemo(() => mapToPartialConsignmentMap(partialConsignments), [partialConsignments])
  const singleShipmentSelected = selectedOrderIds.length === 1

  // this should not happen but need to be handled programmatically
  if (!params.departmentId) {
    setToast({ variant: 'error-dark', text: i18next.t('manualOverride.suggestPageReload') })
    return null
  }

  return (
    <ManualOverrideModalBase open={open} onClose={onClose}>
      <ManualOverrideComponent
        consignments={consignments}
        bulkActionMode={!singleShipmentSelected}
        urlDepartmentId={params.departmentId}
        orderIds={List(selectedOrderIds)}
        url={location.pathname}
        onClose={onClose}
        setNoInitialEventType={true}
      />
    </ManualOverrideModalBase>
  )
}

export const ManualOverrideEventsModal = ({
  open,
  onClose,
  shipment
}: {
  open: boolean
  onClose: () => void
  shipment: Shipment
}) => {
  const location = useLocation()
  const params = useParams()

  return (
    <ManualOverrideModalBase open={open} onClose={onClose}>
      <ManualOverrideComponent
        consignments={shipment.get('consignments')}
        urlDepartmentId={params.departmentId ?? shipment.get('departmentId').toString()}
        orderIds={List([shipment.get('orderId')])}
        url={location.pathname}
        onClose={onClose}
        setNoInitialEventType={true}
      />
    </ManualOverrideModalBase>
  )
}

const ManualOverrideModalBase = ({
  open,
  onClose,
  children
}: {
  open: boolean
  onClose: () => void
  children: ReactNode
}) => (
  <ModalBase
    open={open}
    onClose={onClose}
    title={i18next.t('manualOverride.manuallyAddEvents')}
    closeText={i18next.t('button.close')}
    size="medium"
  >
    {children}
  </ModalBase>
)

interface Props {
  consignments: List<PartialConsignmentMap>
  orderIds: List<OrderIdType>
  urlDepartmentId: string
  url: string
  bulkActionMode?: boolean
  onClose: () => void
  setNoInitialEventType: boolean
}

const ManualOverrideComponent = (props: Props) => (
  <FormWrapper {...props}>
    {(formikRenderProps) => (
      <Form className="flex-1">
        <FormFields showSaveButton={true} {...props} {...formikRenderProps} />
      </Form>
    )}
  </FormWrapper>
)

interface FormFieldsProps extends Omit<FormWrapperProps, 'children'>, FormikRenderProps {
  showSaveButton: boolean
  onClose: () => void
}

const FormFields = ({
  consignments,
  urlDepartmentId,
  orderIds,
  bulkActionMode,
  values,
  isSubmitting,
  packageOptions,
  onClose
}: FormFieldsProps) => {
  const selectedConsignments = consignments.filter((c) => values.packages.includes(c.get('id').toString()))

  const canCollectShipment = selectedConsignments.every((consignment) =>
    consignmentCanBeCollected.contains(consignment.get('state'))
  )
  const canReturnShipment = selectedConsignments.every(
    (consignment) => ConsignmentState.DEVIATED === consignment.get('state')
  )
  const canSortShipment = selectedConsignments.every((consignment) => isH2Order(consignment.get('type')))
  const canMarkAsNotArrivedAtDistributingTerminal = selectedConsignments.every((consignment) =>
    isHDOrder(consignment.get('type'))
  )

  const eventOptions = useMemo(
    () =>
      eventSelectOptions(
        canCollectShipment,
        canReturnShipment,
        canSortShipment,
        canMarkAsNotArrivedAtDistributingTerminal
      ),
    [canCollectShipment, canReturnShipment, canSortShipment, canMarkAsNotArrivedAtDistributingTerminal]
  )

  return (
    <>
      {!bulkActionMode && (
        <div className="mb-4">
          <FormikMultiSelect
            name="packages"
            label={i18next.t('manualOverride.selectPackages')}
            options={packageOptions}
            selected={
              values.packages.length === packageOptions.length
                ? i18next.t('shipmentDetails.allPackages')
                : values.packages.length > 1
                  ? `${values.packages.length} ${i18next.t('shipmentDetails.packages')}`
                  : `${values.packages.length} ${i18next.t('shipmentDetails.package')}`
            }
          />
        </div>
      )}
      <FormikSelect
        validate={requiredFormik}
        required
        name="event"
        label={i18next.t('manualOverride.selectEvent')}
        options={[{ code: '', label: i18next.t('manualOverride.selectEvent') }, ...eventOptions]}
      />
      {values.event !== '' && (
        <LayoutForm>
          <div className="mt-8 p-4 bg-gray-100">
            <EventHeading eventType={values.event as EventType} textType="base" bold />
            <FormFieldsByEvent
              values={values}
              orderIds={orderIds}
              urlDepartmentId={urlDepartmentId}
              consignments={selectedConsignments}
            />
          </div>
        </LayoutForm>
      )}
      <ModalBaseButtons>
        <ButtonBase type="button" variant="secondary" onClick={onClose}>
          {i18next.t('button.cancel')}
        </ButtonBase>
        <div>
          <ButtonBase type="submit" isLoading={isSubmitting} variant="primary">
            {i18next.t('application.save')}
          </ButtonBase>
        </div>
      </ModalBaseButtons>
    </>
  )
}
