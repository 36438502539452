import 'regenerator-runtime/runtime'
import 'whatwg-fetch'
import './i18n/i18n'

import React from 'react'
import { createRoot } from 'react-dom/client'
import { store } from './reducers/store'
import { Settings } from 'luxon'
import { userLanguage } from './utils/language'
import { fetchUser } from './actions/creators/user'
import { initErrorHandlers } from './utils/errorHandlers'
import { initIconLibrary } from './utils/iconLibrary'
import { initCourierLocationWatcher } from './utils/courierLocationWatcher'
import { checkRevision } from './checkRevision'
import { App } from './components/App'
import { initializeSiteAnalytics, initializeSiteMetrics } from '@glow/instrumentation'
import { internalFrontendSentryAPIKey, sentryEnvironment, isProduction } from '@glow/common'
import './index.css'
import '@glow/common/utils/atPolyfill'

Settings.defaultLocale = userLanguage

initIconLibrary()
initErrorHandlers()

if (isProduction()) {
  initializeSiteAnalytics()
}

const apiKey = internalFrontendSentryAPIKey()
if (apiKey) {
  initializeSiteMetrics(apiKey, sentryEnvironment(), 0.001)
}

initCourierLocationWatcher(store)
checkRevision(store.dispatch)

fetchUser()(store.dispatch).catch((err: { body: string }) => {
  if (document.location.pathname.includes('customer')) {
    location.href = JSON.parse(err.body).redirectUrl
  }
})

const container = document.getElementById('app')
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!)
root.render(<App />)
