import { DateTime } from 'luxon'
import { userLanguage } from './language'

function courierHasLocationUpdatesLast12Hours(courier: any) {
  return isUpdatesLast12Hours(courier.getIn(['location', 'updatedAt']))
}

export function isUpdatesLast12Hours(updatedAt: string) {
  const diffInHours = DateTime.fromISO(updatedAt, { locale: userLanguage }).diffNow('hours').hours
  return !(isNaN(Number(diffInHours)) || diffInHours < -12)
}

export const courierShouldBeVisibleInMap = courierHasLocationUpdatesLast12Hours
