import { fromJS, Map } from 'immutable'
import { ApolloAuditLog } from '../types/coreEntitiesTypes'
import { ImmutableMap } from '../types/immutableTypes'
import { CLEAR_APOLLO_AUDIT_LOG, GET_APOLLO_AUDIT_LOG } from '../actions/actionTypes'

interface ApolloAuditLogActionType {
  type: string
  params: { date: string }
  body: Map<number, ImmutableMap<ApolloAuditLog>>
}
const initialState = Map({
  auditLogs: Map()
})

export default (state = initialState, action: ApolloAuditLogActionType) => {
  switch (action.type) {
    case CLEAR_APOLLO_AUDIT_LOG:
      return initialState
    case GET_APOLLO_AUDIT_LOG.SUCCESS:
      return state.withMutations((mState) => {
        return fromJS(action.body).map((v: unknown, k: { toString: () => unknown }) => {
          return mState.setIn(['auditLogs', action.params['date'], k.toString()], v)
        })
      })
    default:
      return state
  }
}
