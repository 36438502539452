import { Collection, List, Map } from 'immutable'
import React from 'react'
import CustomerDeliveryInformation from '../components/CustomerDeliveryInformation'
import PickupDeliveryInformation from '../components/PickupDeliveryInformation'
import { Consignment, Shipment, Slot } from '../types/coreEntitiesTypes'
import { isNotEmpty, zipmapBy } from './collectionUtils'
import { ConsignmentState, getHighestStateFromShipment } from './consignmentState'

export function generateCustomerMarkersFromShipments(
  shipments: List<Collection<number, Consignment>>,
  useModal = false,
  slots: List<Slot>,
  allowEdit = false
) {
  const slotsById = isNotEmpty(slots) ? zipmapBy('id', slots) : Map()
  const deliveries = shipments
    .filter(
      (c) =>
        !List([ConsignmentState.CREATED, ConsignmentState.UNRESOLVED]).contains(
          getHighestStateFromShipment(c as any)
        ) &&
        c.first()?.get('deliveryLat') !== null &&
        c.first()?.get('deliveryLng') !== null
    )
    .map((shipment) => {
      const shipmentState = getHighestStateFromShipment(shipment as any)
      const consignment = shipment.first()
      const slot = slotsById.get(consignment?.get('slotId'))
      return {
        id: consignment?.get('id') + '.delivery',
        lat: consignment?.get('deliveryLat'),
        lng: consignment?.get('deliveryLng'),
        states: [shipmentState],
        heading: `${consignment?.get('deliveryAddress')}, ${consignment?.get('deliveryZipCode')} ${consignment?.get(
          'deliveryZipArea'
        )}`,
        zIndex: consignment?.get('id'),
        information: [
          () =>
            useModal ? (
              <PickupDeliveryInformation
                type={'delivery'}
                key={`${consignment?.get('orderId')}-delivery`}
                shipment={consignment as Shipment}
                slot={slot as Slot}
                allowEdit={allowEdit}
              />
            ) : (
              <CustomerDeliveryInformation
                key={`${consignment?.get('orderId')}-delivery`}
                consignment={consignment as Consignment}
              />
            )
        ]
      }
    })
    .valueSeq()

  const pickups = shipments
    .filter(
      (c) =>
        !List([ConsignmentState.CREATED, ConsignmentState.UNRESOLVED]).contains(
          getHighestStateFromShipment(c as any)
        ) &&
        c.first()?.get('deliveryLat') !== null &&
        c.first()?.get('deliveryLng') !== null
    )
    .map((shipment) => {
      const shipmentState = getHighestStateFromShipment(shipment as any)
      const consignment = shipment.first()
      const slot = slotsById.get(consignment?.get('slotId'))
      return {
        id: consignment?.get('id') + '.pickup',
        lat: consignment?.get('pickupLat'),
        lng: consignment?.get('pickupLng'),
        states: [shipmentState],
        heading: `${consignment?.get('pickupAddress')}, ${consignment?.get('pickupZipCode')} ${consignment?.get(
          'pickupZipArea'
        )}`,
        zIndex: consignment?.get('id'),
        information: [
          () =>
            useModal ? (
              <PickupDeliveryInformation
                type={'pickup'}
                key={`${consignment?.get('orderId')}-pickup`}
                shipment={consignment as Shipment}
                slot={slot as Slot}
                allowEdit={allowEdit}
              />
            ) : (
              <CustomerDeliveryInformation
                key={`${consignment?.get('orderId')}-pickup`}
                consignment={consignment as Consignment}
              />
            )
        ]
      }
    })
    .valueSeq()

  const pickupsAndDeliveries = List()
    .concat(pickups)
    .concat(deliveries)
    // @ts-ignore
    .sort((a, b) => b.states[0].localeCompare(a.states[0]))

  return groupCustomerMarkersOnPosition(pickupsAndDeliveries)
}

function groupCustomerMarkersOnPosition(markers: List<any>) {
  // @ts-ignore
  return markers.reduce((result, marker) => {
    const existingMarker = result.find(({ lat, lng }) => {
      const roundedLat = Math.round(lat * 1000000) / 1000000
      const roundedLng = Math.round(lng * 1000000) / 1000000
      const roundedMarkerLat = Math.round(marker.lat * 1000000) / 1000000
      const roundedMarkerLng = Math.round(marker.lng * 1000000) / 1000000
      return roundedLat === roundedMarkerLat && roundedLng === roundedMarkerLng
    }) as any

    if (!existingMarker) {
      return [...result, marker]
    }

    // @ts-ignore
    const existingMarkerIndex = result.indexOf(existingMarker)

    const updatedMarker = {
      ...existingMarker,
      information: existingMarker.information.concat(marker.information),
      states: marker.states.concat(existingMarker.states),
      highlight: marker.highlight || existingMarker.highlight
    }

    return [...result.slice(0, existingMarkerIndex), updatedMarker, ...result.slice(existingMarkerIndex + 1)]
  }, [])
}
