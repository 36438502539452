import {
  FormikDate,
  FormikInput,
  FormikRadioSelect,
  FormikTime,
  formikValidationRequired
} from '@glow/formik-components'
import { IconFa, LayoutFormRow, LayoutFormSection, Option, Text } from '@glow/ui-components'
import i18next from 'i18next'
import { List } from 'immutable'
import React, { ReactNode, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { departmentsSelector, selectedDepartmentIdsSelector } from '../../../selectors/departmentsSelector'
import { DepartmentIdType, OrderIdType } from '../../../types/coreEntitiesTypes'
import { AppStateType } from '../../../utils/appStateReduxStore'
import { userLanguage } from '../../../utils/language'
import { selectDeviationOptions } from '../../../utils/deviation'
import { ManualOverrideFormProps, PartialConsignmentMap } from './FormWrapper'
import { EventType } from '../../../utils/consignmentEvent'
import { ImmutableMap } from '../../../types/immutableTypes'
import { CourierUserWithActionProps } from '../../../components/unit/CourierUserGrid'
import { useAppDispatch } from '../../../reducers/redux-hooks'
import { useFormikContext } from 'formik'
import { getUnits } from '../../../actions/creators/helpers'
import { consignmentsByOrderIdsSelector } from '../../../selectors/consignmentSelectors'
import { SYSTEMBOLAGET_ALYSTRA_ID } from '../../../components/newShipmentModal/panels/ManualOverrideUtils'
import { isProduction } from '@glow/common'
import {
  activeUnitsWhereUsersCanLoginSelectorSorted,
  unitsWithoutUsersInDepartmentSelectorSorted
} from '../../../selectors/unitSelectors'
import { courierUsersWithAlcoholDeliveryEducationFilterByUnitIdSelectorList } from '../../../components/unit/UnitHelpers'

interface FormSelectorProps {
  values: ManualOverrideFormProps
  orderIds: List<OrderIdType>
  urlDepartmentId: string
  consignments: List<PartialConsignmentMap>
}

export const FormFieldsByEvent = ({ values, orderIds, urlDepartmentId, consignments }: FormSelectorProps) => {
  const selectedDepartmentIds = useSelector((state: AppStateType) => {
    return selectedDepartmentIdsSelector(state, urlDepartmentId)
  })

  switch (values.event) {
    case EventType.SCANNED:
      return <ScannedForm values={values} />
    case EventType.ORDER_SORTED:
    case EventType.NOT_ARRIVED_AT_DISTRIBUTING_TERMINAL:
      return <DateAndTime values={values} />
    case EventType.DEVIATED:
      return (
        <DeviatedForm
          departmentIds={selectedDepartmentIds}
          orderIds={orderIds}
          values={values}
          consignments={consignments}
        />
      )
    case EventType.COLLECTED:
      return <CollectedForm departmentIds={selectedDepartmentIds} orderIds={orderIds} values={values} />
    default:
      return <ReturnedAndDeliveredForm departmentIds={selectedDepartmentIds} orderIds={orderIds} values={values} />
  }
}

interface FormProps {
  values: ManualOverrideFormProps
  orderIds: List<OrderIdType>
  departmentIds: List<DepartmentIdType>
}

interface DeviatedFormProps extends FormProps {
  consignments: List<PartialConsignmentMap>
}

const UnitDriverDateBaseForm = ({
  values,
  orderIds,
  departmentIds
}: Omit<FormProps, 'setFieldValue'> & { children?: ReactNode }) => (
  <LayoutFormSection className="mt-4">
    <UnitDriverForm orderIds={orderIds} departmentIds={departmentIds} />
    <DateAndTime values={values} />
  </LayoutFormSection>
)

const CollectedForm = ({ values, orderIds, departmentIds }: FormProps) => (
  <UnitDriverDateBaseForm values={values} orderIds={orderIds} departmentIds={departmentIds} />
)
const ReturnedAndDeliveredForm = ({ values, orderIds, departmentIds }: FormProps) => (
  <>
    <FormikInput
      name="name"
      className="mt-2"
      validate={(value: string) => formikValidationRequired(value, i18next.t('shipmentDetails.required'))}
      label={i18next.t('consignment.recipientName')}
      required
    />
    <UnitDriverDateBaseForm values={values} orderIds={orderIds} departmentIds={departmentIds} />
  </>
)

const DeviatedForm = ({ values, orderIds, departmentIds, consignments }: DeviatedFormProps) => {
  const options = selectDeviationOptions(consignments)

  const selectedDeviationLabel = useMemo(() => {
    return options.find((courier) => courier.type !== 'header' && courier.code === values.deviationCode)?.label
  }, [values.deviationCode])
  return (
    <>
      <FormikRadioSelect
        name="deviationCode"
        containerClassName="mt-2"
        options={options}
        label={i18next.t('shipmentDetails.selectDeviation')}
        aria-label={i18next.t('shipmentDetails.selectDeviation')}
        placeholder={i18next.t('shipmentDetails.selectDeviation')}
        selectedLabel={selectedDeviationLabel}
      />
      <UnitDriverDateBaseForm values={values} orderIds={orderIds} departmentIds={departmentIds} />
    </>
  )
}

const ScannedForm = ({ values }: { values: ManualOverrideFormProps }) => {
  const departments = useSelector((state: AppStateType) => departmentsSelector(state))
  const departmentsForUser: Option[] = useMemo(
    () =>
      departments.map((department) => ({ code: department.get('id') + '', label: department.get('name') })).toArray(),
    [departments]
  )

  const selectedDepartmentLabel = useMemo(() => {
    return departmentsForUser.find((department) => department.code == values.departmentId)?.label
  }, [departmentsForUser, values.departmentId])

  return (
    <>
      <Text as="p" textType="base" className="text-emphasis-medium mt-2">
        {i18next.t('manualOverride.scannedFormDescription')}
      </Text>
      <FormikRadioSelect
        name="departmentId"
        containerClassName="mt-4"
        options={departmentsForUser}
        label={i18next.t('manualOverride.selectDepartment')}
        aria-label={i18next.t('manualOverride.selectDepartment')}
        placeholder={i18next.t('manualOverride.selectDepartment')}
        selectedLabel={selectedDepartmentLabel}
        validate={(value: string) => formikValidationRequired(value, i18next.t('application.requiredShort'))}
        required
      />
      <DateAndTime values={values} />
    </>
  )
}

const DateAndTime = ({ values }: { values: ManualOverrideFormProps }) => (
  <div className="mt-4 w-1/2 flex gap-4">
    <FormikDate
      value={values.date}
      icon={<IconFa icon={['far', 'calendar-alt']} />}
      name="date"
      lang={userLanguage}
      label={i18next.t('shipmentDetails.selectDate')}
    />
    <FormikTime name="time" label={i18next.t('shipmentDetails.time')} type="time" />
  </div>
)

interface SelectManualEventUnitProps {
  orderIds: List<OrderIdType>
  departmentIds: List<DepartmentIdType>
}

function formatUserOptionText(user: ImmutableMap<CourierUserWithActionProps>): string {
  if (user.get('driverId')) return user.get('name') + ' - ' + user.get('driverId')
  else return user.get('name')
}

function UnitDriverForm({ orderIds, departmentIds }: SelectManualEventUnitProps) {
  const dispatch = useAppDispatch()
  const { values, setFieldValue } = useFormikContext<ManualOverrideFormProps>()

  useEffect(() => {
    dispatch(getUnits(departmentIds.toSet()))
  }, [departmentIds])

  const consignments = useSelector((state: AppStateType) => {
    return consignmentsByOrderIdsSelector(state, orderIds)
  })
  const courierMustHaveAlcoholEducation = consignments.some((c) => c.get('custAlystraId') == SYSTEMBOLAGET_ALYSTRA_ID)
  const units = useSelector((state: AppStateType) =>
    isProduction()
      ? unitsWithoutUsersInDepartmentSelectorSorted(state, departmentIds)
      : activeUnitsWhereUsersCanLoginSelectorSorted(state, departmentIds)
  )
  const unitOptions: Option[] = useMemo(
    () =>
      units.toArray().map((unit) => ({
        code: unit.get('id') + '',
        label: `${unit.get('name')} - ${unit.get('alystraId')}`
      })),
    [units]
  )
  const users = useSelector((state: AppStateType) =>
    courierUsersWithAlcoholDeliveryEducationFilterByUnitIdSelectorList(
      state,
      parseInt(values.unitId),
      courierMustHaveAlcoholEducation
    )
  )

  const userOptions: Option[] = useMemo(
    () =>
      users.toArray().map((user) => ({
        code: user.get('id'),
        label: formatUserOptionText(user)
      })),
    [users]
  )

  useEffect(() => {
    if (users && users.count() === 1) {
      setFieldValue('driverUserId', userOptions[0].code)
    } else {
      setFieldValue('driverUserId', '')
    }
  }, [values.unitId])

  const noActiveDriversMessage = useMemo(
    () =>
      parseInt(values.unitId) > 0 && userOptions.length === 0
        ? i18next.t('manualOverride.noActiveDriverError')
        : undefined,
    [values.unitId, userOptions.length]
  )

  return (
    <>
      <LayoutFormRow columns={2}>
        <FormikRadioSelect
          name="unitId"
          validate={(value: string) => formikValidationRequired(value, i18next.t('shipmentDetails.required'))}
          required
          options={unitOptions}
          label={i18next.t('manualOverride.selectUnit')}
          aria-label={i18next.t('manualOverride.selectUnit')}
          placeholder={i18next.t('manualOverride.selectUnit')}
          selectedLabel={unitOptions.find((courier) => courier.code === values.unitId)?.label}
        />
        <FormikRadioSelect
          name="driverUserId"
          validate={(value: string) => formikValidationRequired(value, i18next.t('shipmentDetails.required'))}
          required
          options={userOptions}
          placeholder={i18next.t('manualOverride.selectDriver')}
          label={i18next.t('manualOverride.selectDriver')}
          aria-label={i18next.t('manualOverride.selectDriver')}
          selectedLabel={userOptions.find((user) => user.code === values.driverUserId)?.label}
          error={Boolean(noActiveDriversMessage)}
          errorMessage={noActiveDriversMessage}
        />
      </LayoutFormRow>
    </>
  )
}
