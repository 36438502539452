import { ConsignmentState } from '@glow/entity-types'
import Delivered from './map-icon-delivered.svg'
import Delivery from './map-icon-delivery-spot.svg'
import Deviated from './map-icon-deviation.svg'
import Pickup from './map-icon-pickup-spot.svg'
import Returned from './map-icon-returned.svg'

export function getMapMarkerFromConsignmentState(consignmentState: ConsignmentState): MarkerVariant {
  if (consignmentState === 'DEVIATED') {
    return 'deviated'
  } else if (consignmentState === 'DELIVERED') {
    return 'delivered'
  } else if (consignmentState === 'RETURNED') {
    return 'returned'
  }
  return 'delivery'
}

export type MarkerVariant = 'pickup' | 'delivery' | 'delivered' | 'deviated' | 'returned'

export const markerColor = {
  deviatedColor: '#B91C1C',
  deliveredColor: '#15803D',
  deliveryColor: '#171717',
  returnedColor: '#c2410c',
  pickupColor: '#A3A3A3'
}

export function getDeliveryMarkerColorFromConsignmentState(consignmentState: ConsignmentState) {
  if (consignmentState === 'DEVIATED') {
    return markerColor.deviatedColor
  } else if (consignmentState === 'DELIVERED') {
    return markerColor.deliveredColor
  } else if (consignmentState === 'RETURNED') {
    return markerColor.returnedColor
  }
  return markerColor.deliveryColor
}

export function getPickupMarkerColorFromConsignmentState(consignmentState: ConsignmentState) {
  if (consignmentState === 'DEVIATED') {
    return markerColor.deviatedColor
  } else if (consignmentState === 'RETURNED') {
    return markerColor.returnedColor
  }
  return markerColor.pickupColor
}

export function markerVariantUrl(variant: MarkerVariant): string {
  switch (variant) {
    case 'pickup':
      return Pickup
    case 'delivery':
      return Delivery
    case 'delivered':
      return Delivered
    case 'deviated':
      return Deviated
    case 'returned':
      return Returned
    default:
      return Delivery
  }
}
