import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { enablePricingEngine, isProduction, useToggle } from '@glow/common'
import i18next from 'i18next'
import { fromJS, List, Map, Set } from 'immutable'
import { DateTime } from 'luxon'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { H3 } from '../../../primitives/Headings'
import Select from '../../../primitives/Select'
// @ts-expect-error
import { Control, Errors } from 'react-redux-form/lib/immutable'
import { useDebounce } from 'use-debounce'
import { CLEAR_PRICE_CORRECTIONS, GET_ORDER_PRICE_DATA_RESET } from '../../../actions/actionTypes'
import { ClearInstantBookingFormButton } from '../../../components/ClearInstantBookingFormButton'
import { PriceExplanationModal } from '../../../components/PriceExplanationModal'
import { DeliveryInfo } from '../../../components/zoneDelivery/DeliveryInfo'
import { PickupAndDeliveryTimeInfo } from '../../../components/zoneDelivery/PickupAndDeliveryTimeInfo'
import { SelectDeliveryTimeWindow } from '../../../components/zoneDelivery/SelectDeliveryTimeWindow'
import { DeliveryTimeWindow, DeliveryTimeWindowIdType } from '../../../domain/deliveryTimeWindow'
import { getServiceDisplayText, IOptimusService } from '../../../domain/optimusService'
import { SecondaryButton } from '../../../primitives/Button'
import { SmallValidationError } from '../../../primitives/ErrorMessages'
import { useAppDispatch } from '../../../reducers/redux-hooks'
import variables from '../../../styles/variables'
import { Department } from '../../../types/coreEntitiesTypes'
import { getSelectedService, getServiceCode, onGetPricePayload } from '../../../utils/alystraUtils'
import { isNotEmpty } from '../../../utils/collectionUtils'
import { timeToStringWithZeroPadding } from '../../../utils/dateTime'
import { required } from '../../../utils/inputValidation'
import { ROLE_PARTNER } from '../../../utils/roles'
import { GroupedServicesAndVasesTexts } from '../../../utils/serviceUtils'
import DatePicker from '../DatePicker'
import InputGroup from '../InputGroup'
import TimePicker from '../TimePicker'
import { emptyVAS, getMeasurementsTotalVolume, getMeasurementsTotalWeight, pureVAS } from '../bookingOrderFunctions'
import {
  CorrectedArticlePrice,
  IAlystraPrice,
  IOptimusRouting,
  IOrderForm,
  IPricedArticle,
  IPriceExplanation
} from '../bookingOrderTypes'
import { serviceComponentNames } from '../bookingSupport'
import { BookingFormADRGoods } from './BookingFormADRGoods'
import BookingFormValueAddedServices from './BookingFormValueAddedServices'
import { getAllArticleCodesInOrder, IPriceFormServicesProps, mergeArticlePrices, OrderPrices } from './OrderPrices'

const getPickupAndDeliveryTimes = (service: IOptimusService) => {
  const instantServiceTime = service.get('instantServiceTime')
  const now = DateTime.local()
  return Map({
    pickupTimeEarliest: instantServiceTime ? timeToStringWithZeroPadding(now) : service.get('isoPickupTimeFrom'),
    pickupTimeLatest: instantServiceTime
      ? timeToStringWithZeroPadding(now.plus({ minutes: instantServiceTime }))
      : service.get('isoPickupTimeTo'),
    deliveryTimeEarliest: instantServiceTime ? timeToStringWithZeroPadding(now) : service.get('isoDeliveryTimeFrom'),
    deliveryTimeLatest: instantServiceTime
      ? timeToStringWithZeroPadding(now.plus({ minutes: instantServiceTime }))
      : service.get('isoDeliveryTimeTo')
  })
}

const serviceOption = (serviceTexts: GroupedServicesAndVasesTexts) => (service: IOptimusService) => {
  const serviceId = service.get('id')
  const serviceCode = service.get('serviceCode')
  return (
    <option key={`service-${serviceId}-${serviceCode}`} value={getServiceCode(service)}>
      {getServiceDisplayText(service, serviceTexts)}
    </option>
  )
}

interface Props extends IPriceFormServicesProps {
  formValues: IOrderForm
  showPickupDeliveryTimes: boolean
  routing: IOptimusRouting
  customizedRouting: IOptimusRouting
  departmentId: number
  isLoadingPrice: boolean
  getOrderPrice: (payload: Map<string, any>) => void
  priceInfo: IAlystraPrice | undefined
  priceExplanations: List<IPriceExplanation> | undefined
  pricedArticles: List<IPricedArticle> | undefined
  setPageStateValue: (key: string, value: any) => void
  mergeFormValues: (model: string, values: Map<string, any>) => void
  addToFormList: (formName: string, values: Map<string, string>) => void
  removeFromFormList: (model: string, index: number) => void
  servicesLoaded: boolean
  allDepartments: List<Department>
  clearComponents: (components: List<string>) => void
  hdOrder: boolean
  baggageOrder: boolean
  deliveryDays: Set<number>
  deliveryTimeWindows: Map<DeliveryTimeWindowIdType, DeliveryTimeWindow>
  serviceTexts: GroupedServicesAndVasesTexts
  correctedArticlePrices: List<IPricedArticle> | undefined
  orderId?: number
  slotId?: number
  role: string
}

const BookingFormServices = ({
  formValues,
  showPickupDeliveryTimes,
  services,
  routing,
  customizedServices,
  customizedRouting,
  departmentId,
  isLoadingPrice,
  getOrderPrice,
  priceInfo,
  pricedArticles,
  priceExplanations,
  setPageStateValue,
  mergeFormValues,
  addToFormList,
  removeFromFormList,
  servicesLoaded,
  allDepartments,
  clearComponents,
  hdOrder,
  baggageOrder,
  deliveryDays,
  deliveryTimeWindows,
  serviceTexts,
  correctedArticlePrices,
  slotId,
  role,
  orderId,
  needsZeroPriceApproval,
  hasZeroPriceApproval
}: Props) => {
  const onSelectService = (value: string) => {
    const service = getSelectedService(value, services, customizedServices)
    if (!service) {
      setPageStateValue('showPickupDeliveryTimes', false)
      return
    }

    !(hdOrder || baggageOrder) && mergeFormValues('createOrderForm', getPickupAndDeliveryTimes(service))
    setPageStateValue('showPickupDeliveryTimes', true)
    dispatch({ type: CLEAR_PRICE_CORRECTIONS })
  }

  const [showMapModal, toggleMapModal] = useToggle()
  const corrections = (fromJS(formValues.get('correctedArticlePrices')) as List<CorrectedArticlePrice>) || List()

  const [editablePrices, setEditablePrices] = useState<Map<string, boolean>>(Map())
  const editInProgress = [...editablePrices.values()].find((e) => e) || false
  const showManualColumn =
    editInProgress || Boolean(corrections?.find((c) => !!c.get('customerPrice') || !!c.get('resourcePrice')))

  const [debouncedFormValues] = useDebounce(
    JSON.stringify(
      formValues.withMutations((form) => {
        return form
          .remove('correctedArticlePrices')
          .remove('pickupInstructions')
          .remove('pickupSecondPhone')
          .remove('pickupEmail')
          .remove('pickupPhone')
          .remove('pickupName')
          .remove('serviceTexts')
          .remove('deliverySecondPhone')
          .remove('deliveryEmail')
          .remove('deliveryPhone')
          .remove('deliveryName')
          .remove('deliveryInstructions')
          .remove('pickupContactPerson')
          .remove('pickupInstructions')
          .remove('recipientRef')
          .remove('invoiceInfo')
          .remove('manualInvoiceInfo')
          .remove('specificationDescription')
          .remove('customerInfo')
          .remove('customerRef')
          .remove('deliveryContactPerson')
          .remove('orderNote')
          .remove('emailId')
          .remove('deliveredEvent')
          .remove('collectedEvent')
          .remove('createdEvent')
          .remove('customerContact')
          .remove('allowIncompletePrice')
      })
    ),
    500
  )

  useEffect(() => {
    mergeFormValues(
      'createOrderForm',
      Map({
        serviceId: null
      })
    )
  }, [])

  useEffect(() => {
    enablePricingEngine() && onGetPriceData()
  }, [debouncedFormValues])

  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch({ type: GET_ORDER_PRICE_DATA_RESET })
  }, [])

  useEffect(() => {
    if (correctedArticlePrices) {
      const allArticleCodes = getAllArticleCodesInOrder(
        correctedArticlePrices,
        pricedArticles ?? List(),
        services,
        customizedServices,
        valueAddedServices ?? List()
      )

      mergeFormValues(
        'createOrderForm',
        mergeArticlePrices(allArticleCodes, correctedArticlePrices, formValues.get('correctedArticlePrices') ?? List())
      )

      const resetEditablePrices = correctedArticlePrices.reduce((acc, cap) => {
        acc.set(`customerPrice_${cap.get('articleCode')}`, false)
        acc.set(`resourcePrice_${cap.get('articleCode')}`, false)
        return acc
      }, Map<string, boolean>())

      setEditablePrices(() => resetEditablePrices)
    }
  }, [JSON.stringify(correctedArticlePrices), JSON.stringify(pricedArticles)])

  useEffect(() => {
    const dtw = availableDeliveryTimeWindows().find(
      (dtw) =>
        dtw.get('pickupTimeFrom') === formValues.get('pickupTimeEarliest') &&
        dtw.get('pickupTimeTo') === formValues.get('pickupTimeLatest')
    )
    const dtwId = dtw?.get('id')
    dtwId &&
      mergeFormValues(
        'createOrderForm',
        Map({
          deliveryTimeWindowId: dtwId
        })
      )
  }, [deliveryTimeWindows])

  const addVAS = () => {
    addToFormList('createOrderForm.additionalServices', emptyVAS())
  }

  const removeVAS = (i: number) => {
    removeFromFormList('createOrderForm.additionalServices', i)
    dispatch({
      type: CLEAR_PRICE_CORRECTIONS,
      articleCode: formValues?.get('additionalServices')?.get(i)?.get('vasCode')
    })
  }

  const onGetPrice = (values: IOrderForm, totalWeight: number, totalVolume: number) => {
    const payload = onGetPricePayload(
      values,
      totalWeight,
      totalVolume,
      routing,
      customizedRouting,
      services,
      customizedServices,
      allDepartments,
      departmentId,
      slotId,
      deliveryTimeWindows.get(formValues.get('deliveryTimeWindowId')) ||
        (fromJS({
          pickupTimeFrom: values.get('pickupTimeEarliest'),
          pickupTimeTo: values.get('pickupTimeLatest'),
          deliveryTimeFrom: values.get('deliveryTimeEarliest'),
          deliveryTimeTo: values.get('deliveryTimeLatest')
        }).toMap() as DeliveryTimeWindow),
      orderId
    )
    if (isNotEmpty(payload)) getOrderPrice(payload)
  }

  const onGetPriceData = () => {
    if (canFetchPrice()) {
      const measurements = formValues.get('measurements')
      onGetPrice(formValues, getMeasurementsTotalWeight(measurements), getMeasurementsTotalVolume(measurements))
    }
  }

  const canFetchPrice = () => {
    if (formValues.get('measurements') && hasAddress('pickup') && hasAddress('delivery')) {
      return true
    }

    return false
  }

  const hasAddress = (type: 'delivery' | 'pickup') =>
    formValues.get(`${type}Address`) !== '' &&
    formValues.get(`${type}ZipCode`) !== '' &&
    formValues.get(`${type}ZipArea`) !== ''

  const getCustomerUnitPriceText = (customerPrice: string, unitPrice: string) => {
    const customerPriceText = i18next.t('instant.booking.customerPrice')
    const unitPriceText = i18next.t('instant.booking.resourcePrice')
    return `${customerPriceText}: ${Number(customerPrice).toFixed(2)} - ${unitPriceText}: ${Number(unitPrice).toFixed(
      2
    )}`
  }

  const isSHDService = () => {
    return (
      getSelectedService(formValues.get('serviceId'), services, customizedServices)?.get('useCustomerZones') || false
    )
  }

  const availableDeliveryTimeWindows = (date?: string): Map<DeliveryTimeWindowIdType, DeliveryTimeWindow> => {
    const pickupDate = date ? date : formValues.get('pickupDate')
    const timeWindows = deliveryTimeWindows

    if (pickupDate && timeWindows) {
      const weekday = DateTime.fromISO(pickupDate).weekday
      return timeWindows.filter((it: DeliveryTimeWindow) => it.get('dayOfWeek') === weekday - 1)
    }

    return Map()
  }

  const onDeliveryTimeWindowChange = (deliveryTimeWindow?: DeliveryTimeWindow) => {
    if (deliveryTimeWindow) {
      mergeFormValues(
        'createOrderForm',
        Map({
          deliveryTimeWindowId: deliveryTimeWindow.get('id'),
          pickupTimeEarliest: timeToStringWithZeroPadding(DateTime.fromISO(deliveryTimeWindow.get('pickupTimeFrom'))),
          pickupTimeLatest: timeToStringWithZeroPadding(DateTime.fromISO(deliveryTimeWindow.get('pickupTimeTo'))),
          deliveryTimeEarliest: timeToStringWithZeroPadding(DateTime.fromISO(deliveryTimeWindow.get('timeFrom'))),
          deliveryTimeLatest: timeToStringWithZeroPadding(DateTime.fromISO(deliveryTimeWindow.get('timeTo')))
        })
      )
      dispatch({ type: CLEAR_PRICE_CORRECTIONS })
    }
  }

  const valueAddedServices = services.concat(customizedServices).filter(pureVAS)

  const requireTimes = !(hdOrder || baggageOrder) || !!formValues.get('pickupDate')

  return (
    <InputGroup aria-label="Services form">
      <H3>{i18next.t('instant.booking.service')}</H3>
      <div style={{ marginBottom: '0.75em' }}>
        <Select expand>
          <Control.select
            model=".serviceId"
            id="serviceId"
            style={{ paddingTop: '0.5rem' }}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              onSelectService(e.target.value)
            }}
            defaultValue={''}
            validators={{ required }}
            disabled={!servicesLoaded}
          >
            <option value={''}>{i18next.t('instant.booking.serviceSelect')}</option>
            {isNotEmpty(customizedServices) && (
              <>
                <option value={''} disabled>
                  {i18next.t('instant.booking.customizedServices')}
                </option>
                {customizedServices.filterNot(pureVAS).map(serviceOption(serviceTexts))}
              </>
            )}
            {isNotEmpty(services) && (
              <>
                <option value={''} disabled>
                  {i18next.t('instant.booking.standardServices')}
                </option>
                {services.filterNot(pureVAS).map(serviceOption(serviceTexts))}
              </>
            )}
          </Control.select>
        </Select>
        <Errors
          className="errors"
          model=".serviceId"
          show="touched"
          wrapper={SmallValidationError}
          messages={{ required: `${i18next.t('application.required')}` }}
        />
      </div>
      {isNotEmpty(deliveryDays) && isSHDService() && (
        <DeliveryInfo timeWindows={deliveryTimeWindows} deliveryDays={deliveryDays} />
      )}
      <div
        style={{
          visibility: showPickupDeliveryTimes ? 'visible' : 'hidden',
          display: 'grid',
          gridTemplateColumns: 'auto 4fr 5fr 2fr auto 2fr 5fr 2fr auto 2fr',
          gridColumnGap: '0.5em',
          marginBottom: '0.5em'
        }}
      >
        <div
          style={{
            gridColumn: 1,
            gridRow: 1,
            padding: '0.5em',
            textTransform: 'uppercase'
          }}
        >
          {i18next.t('instant.booking.date')}
        </div>
        <div style={{ gridColumn: 2, gridRow: 1 }}>
          <Control.input
            model=".pickupDate"
            id={'createOrderForm.pickupDate'}
            component={DatePicker}
            controlProps={{ preventSubmitOnEnter: true }}
            validators={hdOrder || baggageOrder ? {} : { required }}
            defaultValue={hdOrder || baggageOrder ? '' : DateTime.local().toISODate()}
            placeholder={'YYYY-MM-DD'}
          />
          <Errors
            className="errors"
            model=".pickupDate"
            show="touched"
            wrapper={SmallValidationError}
            messages={{
              required: `${i18next.t('application.required')}`
            }}
          />
        </div>
        {isNotEmpty(deliveryDays) && isSHDService() ? (
          <>
            <div style={{ gridColumn: 5, gridRow: 1 }}>
              <Control.input
                id={'createOrderForm.deliveryTimeWindowId'}
                model=".deliveryTimeWindowId"
                component={SelectDeliveryTimeWindow}
                style={{ padding: '0.6rem' }}
                timeWindowId={formValues.get('deliveryTimeWindowId')}
                timeWindows={availableDeliveryTimeWindows()}
                onTimeWindowChange={(id: DeliveryTimeWindowIdType) =>
                  onDeliveryTimeWindowChange(deliveryTimeWindows.get(id))
                }
              />
              <Errors
                className="errors"
                model=".deliveryTimeWindowId"
                show="touched"
                wrapper={SmallValidationError}
                messages={{ validNumber: `${i18next.t('application.required')}` }}
              />
              <div
                style={{
                  gridColumn: 3,
                  gridRow: 1,
                  padding: '0.5em',
                  textAlign: 'right',
                  textTransform: 'uppercase'
                }}
              >
                <PickupAndDeliveryTimeInfo
                  pickupTimeEarliest={formValues.get('pickupTimeEarliest')}
                  pickupTimeLatest={formValues.get('pickupTimeLatest')}
                  deliveryTimeEarliest={formValues.get('deliveryTimeEarliest')}
                  deliveryTimeLatest={formValues.get('deliveryTimeLatest')}
                />
              </div>
            </div>
          </>
        ) : (
          requireTimes && (
            <>
              <div
                style={{
                  gridColumn: 3,
                  gridRow: 1,
                  padding: '0.5em',
                  textAlign: 'right',
                  textTransform: 'uppercase'
                }}
              >
                {i18next.t('instant.booking.pickup')}
              </div>
              <div style={{ gridColumn: 4, gridRow: 1 }}>
                <Control.text
                  autoComplete="nope"
                  model=".pickupTimeEarliest"
                  id="pickupTimeEarliest"
                  placeholder="hh:mm"
                  component={TimePicker}
                  controlProps={{ preventSubmitOnEnter: true }}
                  style={{ padding: '0.6rem' }}
                  validators={requireTimes ? { required } : {}}
                />
                <Errors
                  className="errors"
                  model=".pickupTimeEarliest"
                  show="touched"
                  wrapper={SmallValidationError}
                  messages={{
                    required: `${i18next.t('application.requiredShort')}`
                  }}
                />
              </div>
              <div style={{ gridColumn: 5, gridRow: 1, paddingTop: '0.5em' }}>{'-'}</div>
              <div style={{ gridColumn: 6, gridRow: 1 }}>
                <Control.text
                  autoComplete="nope"
                  model=".pickupTimeLatest"
                  id="pickupTimeLatest"
                  placeholder="hh:mm"
                  component={TimePicker}
                  controlProps={{ preventSubmitOnEnter: true }}
                  style={{ padding: '0.6rem' }}
                  validators={requireTimes ? { required } : {}}
                />
                <Errors
                  className="errors"
                  model=".pickupTimeLatest"
                  show="touched"
                  wrapper={SmallValidationError}
                  messages={{
                    required: `${i18next.t('application.requiredShort')}`
                  }}
                />
              </div>
              <div
                style={{
                  gridColumn: 7,
                  gridRow: 1,
                  paddingTop: '0.5em',
                  textAlign: 'right',
                  textTransform: 'uppercase'
                }}
              >
                {i18next.t('instant.booking.delivery')}
              </div>
              <div style={{ gridColumn: 8, gridRow: 1 }}>
                <Control.text
                  autoComplete="nope"
                  model=".deliveryTimeEarliest"
                  id="deliveryTimeEarliest"
                  placeholder="hh:mm"
                  component={TimePicker}
                  controlProps={{ preventSubmitOnEnter: true }}
                  style={{ padding: '0.6rem' }}
                  validators={requireTimes ? { required } : {}}
                />
                <Errors
                  className="errors"
                  model=".deliveryTimeEarliest"
                  show="touched"
                  wrapper={SmallValidationError}
                  messages={{
                    required: `${i18next.t('application.requiredShort')}`
                  }}
                />
              </div>
              <div style={{ gridColumn: 9, gridRow: 1, paddingTop: '0.5em' }}>{'-'}</div>
              <div style={{ gridColumn: 10, gridRow: 1 }}>
                <Control.text
                  autoComplete="nope"
                  model=".deliveryTimeLatest"
                  id="deliveryTimeLatest"
                  placeholder="hh:mm"
                  component={TimePicker}
                  controlProps={{ preventSubmitOnEnter: true }}
                  style={{ padding: '0.6rem' }}
                  validators={requireTimes ? { required } : {}}
                />
                <Errors
                  className="errors"
                  model=".deliveryTimeLatest"
                  show="touched"
                  wrapper={SmallValidationError}
                  messages={{
                    required: `${i18next.t('application.requiredShort')}`
                  }}
                />
              </div>
            </>
          )
        )}
      </div>
      <BookingFormValueAddedServices
        formValues={formValues}
        valueAddedServices={valueAddedServices}
        onVASAdded={addVAS}
        onVASRemoved={removeVAS}
        disableSelection={!servicesLoaded}
      />
      <BookingFormADRGoods formValues={formValues} />
      {role !== ROLE_PARTNER && !(isProduction() && hdOrder) && (
        <>
          {enablePricingEngine() && (
            <SecondaryButton
              className="m-4"
              disabled={isLoadingPrice}
              onClick={(event) => {
                event.preventDefault()
                onGetPriceData()
              }}
            >
              <FontAwesomeIcon icon="dollar-sign" />
              <span style={{ paddingLeft: '0.5em' }}>{i18next.t('instant.booking.calculatePrice')}</span>
            </SecondaryButton>
          )}
          {!pricedArticles && enablePricingEngine() && (
            <div style={{ paddingTop: '1em', paddingBottom: '0.75em' }}>
              {priceInfo && (
                <span style={{ marginLeft: '1em', marginRight: '1em', fontStyle: 'italic' }}>
                  {getCustomerUnitPriceText(priceInfo.get('netCustomerPrice'), priceInfo.get('netResourcePrice'))}
                </span>
              )}
              {priceExplanations && (
                <div className="inline-block" onClick={toggleMapModal}>
                  {<FontAwesomeIcon icon="info-circle" color={variables.newColors.primary} cursor="help" />}
                </div>
              )}
            </div>
          )}
          {priceExplanations && (
            <PriceExplanationModal
              {...{ priceExplanations: priceExplanations }}
              showModal={showMapModal}
              toggleModal={toggleMapModal}
            />
          )}
          {pricedArticles && (
            <OrderPrices
              corrections={corrections}
              customizedServices={customizedServices}
              editablePrices={editablePrices}
              formValues={formValues}
              pricedArticles={pricedArticles}
              services={services}
              setEditablePrices={setEditablePrices}
              showManualColumn={showManualColumn}
              togglePriceExplanations={toggleMapModal}
              valueAddedServices={valueAddedServices}
              mergeForm={(map) => mergeFormValues('createOrderForm', map)}
              enableEdit={true}
              needsZeroPriceApproval={needsZeroPriceApproval}
              hasZeroPriceApproval={hasZeroPriceApproval}
              formMode="redux-form"
            />
          )}
        </>
      )}
      <ClearInstantBookingFormButton clearComponents={clearComponents} components={serviceComponentNames} />
    </InputGroup>
  )
}

export default BookingFormServices
