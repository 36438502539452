import { IconName } from '@fortawesome/fontawesome-svg-core'
import {
  HousingInformation,
  I18nTextProps,
  OrderType,
  UserAccessKey,
  SessionUser as UserBase
} from '@glow/entity-types'
import i18next from 'i18next'
import { List, Map, Set } from 'immutable'
import { DateTime } from 'luxon'
import { AnyData } from '../actions/creators/baseHelpers'
import { FlightInfoEntity } from '../domain/bringOrder'
import { Customer, CustomerIdType } from '../domain/customer'
import { ServiceDeliveryType } from '../pages/admin/service-matrix-and-texts/utils'
import { ValueAddedService } from '../pages/instant/bookingOrderTypes'
import { EventType } from '../utils/consignmentEvent'
import { ConsignmentState } from '../utils/consignmentState'
import {
  DepartmentType,
  DepartmentTypes,
  OrderBillingInfo,
  OrderBillingInfoProps
} from '../utils/departmentAndDepartmentGroupUtils'
import { ImmutableMap } from './immutableTypes'

interface RoutePointBaseProps {
  id: number
  type: string
  consignmentId: number
  arrival: string
  arrivalCached: DateTime
  adjustedArrival: string
  lat: number
  lng: number
  ignore: boolean
  slotId: SlotIdType
  waitingTimeSec: number
  tripNumber?: number
  endTime?: ISODateTimeString
}

export interface RoutePointSuggestionProps {
  id: number
  type: string
  consignmentId: ConsignmentIdType
  arrival: string
  adjustedArrival?: string
  adjustedArrivalCached?: DateTime
  arrivalCached: DateTime
  slotId: SlotIdType
  sortIndex: number
  onCar: boolean
  previousArrival: string
  isFlex: boolean
  isForcedOrder: boolean
  isAssigned?: boolean
  waitingTimeSec: number
  tripNumber?: number
  endTime?: string
}

export interface RoutePointSuggestion extends ImmutableMap<RoutePointSuggestionProps> {}

export interface VanillaRoutePoint extends RoutePointBaseProps {
  pickupLocation: string
  deliveryLocation: string
}

export interface OtherRoutePointWithAddress extends RoutePointBaseProps {
  depotAddress: string
}

export interface OtherRoutePointWithDuration extends RoutePointBaseProps {
  duration: string
}

export type SlotIdType = number
export type DepartmentIdType = number
export type CustomerRefType = string
export type OrderIdType = number
export type ConsignmentIdType = number
export type ConsignmentEventId = number
export type ConsignmentEventActionId = number
export type CourierLocationIdType = number
export type PackageIdType = string
export type UnitIdType = number
export type VehicleIdType = number

export type ISODateString = string
export type ISODateTimeString = string
export type ISOTimeString = string

export type OtherRoutePoint = OtherRoutePointWithAddress | OtherRoutePointWithDuration
export type RoutePoint = ImmutableMap<OtherRoutePoint | VanillaRoutePoint>
export type RoutePointOrSuggestion = ImmutableMap<
  ((OtherRoutePoint | VanillaRoutePoint) & Partial<RoutePointSuggestionProps>) | RoutePointSuggestionProps
>

export type ServiceMatrixIdType = number
export type Second = number
export type Minute = number
export type CustomerAlystraId = string
export type DepartmentAlystraId = string
export type DeliveryMetaDataId = number

export interface EntityProps {
  id: number
  isAssignable?: boolean
  isEditable?: boolean
  isDeletable?: boolean
  isSelectable?: boolean
  isDeletedShipmentCopyable?: boolean
  isDeletedShipmentRestorable?: boolean
  isDeletedShipment?: boolean
  isDuplicable?: boolean
}

export interface Entity<T> extends ImmutableMap<EntityProps & T> {}
export interface I18nText extends ImmutableMap<I18nTextProps> {}

export interface ConsignmentProps {
  id: ConsignmentIdType
  departmentId: DepartmentIdType
  orderId: OrderIdType
  slotId: SlotIdType | null
  customerId: CustomerIdType
  customer?: Customer
  custId: CustomerIdType
  custAlystraId: string
  custAlystraSubcustomer: string
  custName: string
  shipmentId: string
  pickupName: string
  pickupAddress: string | null
  pickupZipArea: string
  pickupZipCode: string
  pickupAddressLat: number
  pickupAddressLng: number
  pickupCountry: string
  pickupPhone: string
  pickupSecondPhone?: string | null
  pickupEmail?: string | null
  pickupLat: number
  pickupLng: number
  pickupTimeEarliest: ISODateTimeString
  pickupTimeEarliestCached: DateTime
  pickupTimeLatest: ISODateTimeString
  pickupTimeLatestCached: DateTime
  pickupInstructions: string
  pickupContactPerson?: string
  deliveryName: string
  deliveryAddress: string | null
  deliveryZipArea: string
  deliveryZipCode: string
  deliveryAddressLat: number
  deliveryAddressLng: number
  deliveryCountry: string
  deliveryPhone: string
  deliverySecondPhone?: string | null
  deliveryEmail?: string | null
  deliveryLat: number
  deliveryLng: number
  deliveryInstructions: string
  deliveryTimeEarliest: ISODateTimeString
  deliveryTimeEarliestCached: DateTime
  deliveryTimeLatest: ISODateTimeString
  deliveryTimeLatestCached: DateTime
  deliveryContactPerson?: string
  packageId: string
  customerRef?: CustomerRefType
  recipientRef?: CustomerRefType
  serviceCode: string
  state: ConsignmentState
  name: string
  orderInvoicingStatus: boolean | null
  orderInvoicingStatusNew?: string
  specificationDescription: string
  information: string
  weightValue: number
  weightUnit: string
  heightValue: number
  heightUnit: string
  lengthValue: number
  lengthUnit: string
  widthValue: number
  widthUnit: string
  volumeValue: number
  volumeUnit: string
  loadingMetersValue: number
  loadingMetersUnit: string
  totalloadingMetersValue: number
  totalloadingMetersUnit: string
  palletFootPrintsValue: number
  palletFootPrintsUnit: string
  totalPalletFootPrintsValue: number
  totalPalletFootPrintsUnit: string
  presetDimension: string
  xmlServiceCode: string
  vasCodes: List<string>
  vasDetails: List<ValueAddedService>
  offeredTimeLatest: ISODateTimeString | null
  preplannedToUnit: string | null
  selectedDepartmentId: string | null
  totalVolumeValue: number
  totalVolumeUnit: string
  totalWeightValue: number
  totalWeightUnit: string
  externalId: string
  deliverySms: boolean
  deliveryDisplayType: string
  vehicleType?: string
  label?: string
  emailId?: string
  createdEvent?: boolean
  collectedEvent?: boolean
  deliveredEvent?: boolean
  netCustomerPrice: number | null
  netResourcePrice?: number
  slotName?: string
  estimatedArrivalTime?: ISODateTimeString
  estimatedPickupArrivalTime?: ISODateTimeString
  quantity?: number
  serviceLevel: 'premium' | 'standard' | 'none'
  custContact?: string
  courierId?: UnitIdType
  driverUserId?: number
  plannedDeliveryDate?: DateTime
  orderedDeliveryDate?: DateTime
  originalPickupAddress: string | null
  originalPickupZipCode: string
  originalPickupZipArea: string
  originalPickupCountry: string
  originalDeliveryAddress: string | null
  originalDeliveryZipCode: string
  originalDeliveryZipArea: string
  originalDeliveryCountry: string
  arrivedAtDip: boolean | null
  source?: string
  departmentType: DepartmentType
  location: string | null
  originalDeliveryTimeEarliest: ISODateTimeString | null
  originalDeliveryTimeLatest: ISODateTimeString | null
  type: string | null
  preAdvices: number | null
  preAdviceScheduledDate: DateTime | null
  preAdviceStatus: string | null
  hasPreAdviceSetting: boolean | null
  returnToSender: boolean | null
  arrivedAtHub: boolean | null
  packageTypeCode: string | null
  customerTotalPrice: number | null
  carrierTotalPrice: number | null
  createdAt: ISODateTimeString | null
  failed: boolean | null
  deliveryAttempts: number | null
  specificationSize?: string
  multilegId?: number
  multilegType?: string
  scanToCreatePackages: boolean
  groupId?: string
}

export interface Consignment extends ImmutableMap<ConsignmentProps> {}

export interface ShipmentProps extends ConsignmentProps {
  id: number
  noOfPackages: number
  consignments: List<Consignment>
  consignmentIds: Set<ConsignmentIdType>
  priority?: string
  groupId?: string
  groupName?: string
  groupOrder?: number
  paidTogether?: boolean
  departmentIdOverridden?: boolean
  multilegId?: number
  multilegSeqNumber?: number
  deptName?: string
  vehicleType?: string
  deletedBy: string
  deletedByName: string
  reason: string
  courierId: number
  deliveredTime: DateTime
  actualPickupTime: DateTime
  etaSmsSentAt: ISODateTimeString
  note: string | null | undefined
  isReturned: boolean
  shipmentState?: ConsignmentState
  deletedOrderDetailsPresent?: boolean
  isMultileg?: boolean
  isLastLeg?: boolean
  deviationType?: string
  deviationCode?: string
  deviation?: string
  isDeletedShipment?: boolean
  serviceCodeText: string
  vasCodesTexts: string
  pickupDurationSeconds: string
  deliveryDurationSeconds: string
  packagesArrivedAtDip: ShipmentSyncedStatus | null
  packagesCollected: ShipmentSyncedStatus | null
  packagesDelivered: ShipmentSyncedStatus | null
  type: OrderType | null
  courierName?: string
  unitId?: UnitIdType
  isCredited: boolean
}

export type ShipmentSyncedStatus = 'no' | 'partial' | 'yes'

export interface Shipment extends ImmutableMap<ShipmentProps> {}

export interface SearchShipmentProps extends ShipmentProps {
  driverName?: string
}
export interface SearchShipment extends ImmutableMap<SearchShipmentProps> {}

export type DeliveryDisplayType = 'customized' | 'instant'
export type DepartmentServiceLevel = 'standard' | 'none'

export interface DepartmentProps {
  address?: string
  alystraId: string
  areaId: number
  autoInvoiceTime?: string
  autoInvoiceOffsetDays: number
  autoRecurrentTime?: string
  autoRouteCreationTime?: string
  autoRouteOptimizationTime?: string
  country: string
  deliveryDisplayType: DeliveryDisplayType
  departmentType: DepartmentTypes
  description: string | null
  emailAddresses: List<string> | null
  id: DepartmentIdType
  lat: number
  lng: number
  name: string
  npsId: string | null
  serviceLevel: DepartmentServiceLevel | null
  timeZone: string
  unitNumber: string | null
  defaultAlystraUnitId?: string
  zipArea: string | null
  zipCode: string | null
  orderBillingInfos: List<OrderBillingInfo> | null
  terminalName: string | null
  delegatedDepartment: string | null
  disableSmsOnSlot: boolean
  defaultTemplate: number | null
}

export interface DepartmentFormValues {
  address: string
  alystraId: string
  enableAutoInvoice?: boolean
  autoInvoiceTime?: string
  autoInvoiceOffsetDays?: number
  autoInvoiceEnabled?: boolean
  autoRecurrentTime: string
  autoRouteCreationTime: string
  autoRouteOptimizationTime: string
  country: string
  deliveryDisplayType: string
  department?: number
  departmentType: string
  description: string
  emailAddress: string
  lat: number
  lng: number
  name: string
  npsId: string
  serviceLevel: string
  timeZone: string
  unitNumber: string
  defaultAlystraUnitId: string
  zipArea: string
  zipCode: string
  orderBillingInfos: OrderBillingInfoProps[] | null
  terminalName?: string
  delegatedDepartment: string | null
  disableSmsOnSlot: boolean
}

export interface Department extends ImmutableMap<DepartmentProps> {}

export interface DepartmentPropsWithNumberOfCourierAndPlanner extends DepartmentProps {
  numberOfPlanners: number | string
  numberOfDepartmentGroups: number
}
export interface DepartmentWithNumberOfCourierAndPlanner
  extends ImmutableMap<DepartmentPropsWithNumberOfCourierAndPlanner> {}

export const isAirExpressDepartment = (department?: Department): boolean =>
  department?.get('departmentType') === 'airExpress' || false

export const isH2Department = (department?: Department): boolean => department?.get('departmentType') === 'h2' || false

export const isHdDepartment = (department?: Department): boolean => department?.get('departmentType') === 'hd' || false

export const isParcelDepartment = (department?: Department): boolean =>
  department?.get('departmentType') === 'parcel' || false

export const isPostenDepartment = (department?: Department): boolean =>
  department?.get('departmentType') === 'posten' || false

export const orderTypeSupportsPreAdvice = (orderType: OrderType | null): boolean =>
  orderType === 'HD' || orderType == 'Baggage'

export interface IBaseConsignmentEvent {
  id: ConsignmentEventId
  type: string
  consignmentId: ConsignmentIdType
  departmentId?: DepartmentIdType
  orderId: number
  createdAt: ISODateTimeString
  name?: string
  data?: AnyData
  eventTime: ISODateTimeString
  packageId?: string
  slotId?: SlotIdType
  role?: string
  custId?: CustomerIdType
}

export interface BaseConsignmentEvent extends ImmutableMap<IBaseConsignmentEvent> {}

export interface IConsignmentEvent extends IBaseConsignmentEvent {
  type: EventType
}

export interface ConsignmentEvent extends ImmutableMap<IConsignmentEvent> {}

export const isH2Order = (type?: string | null): boolean => type === 'H2'

export const isHDOrder = (type?: string | null): boolean => type === 'HD'

export const isHdOrder = (order?: Order): boolean =>
  order?.get('type') === 'HD' || order?.get('source') === 'Happy Flow'

export const isBaggageOrder = (order?: Order): boolean =>
  order?.get('type') === 'Baggage' || order?.get('source') === 'BaggageSolutions'

interface ADRRestriction
  extends ImmutableMap<{
    type: string
    value: string
  }> {}

export interface DangerousGoods
  extends ImmutableMap<{
    hazardClass: string
    hazardIdentifier: string
    hazardPoints: number
    id: number
    netWeightKg: number
    orderId: number
    packingGroup: string
    regulationCode: string
    restrictions: Array<ADRRestriction>
    technicalName: string
  }> {}

export interface Order
  extends ImmutableMap<{
    id: OrderIdType
    shipmentId: string
    manualInvoiceInfo: string
    invoiceInfo: string
    customerId: CustomerIdType
    departmentIdOverridden: boolean
    departmentId: number
    groupOrder?: number
    groupId?: string
    groupName?: string
    paidTogether?: boolean
    multilegId?: number
    multilegSeqNumber?: number
    vehicleType?: string
    emailId?: string
    createdEvent?: boolean
    collectedEvent?: boolean
    deliveredEvent?: boolean
    orderNote: string | undefined
    source: string | undefined
    customerContact: string | undefined
    type: OrderType
    mawbNo?: string
    awbNo?: string
    flightInfo?: FlightInfoEntity // TODO(steintore) AirExpress - should be a separate entity
    dangerousGoodss?: DangerousGoods[]
    parentId: OrderIdType | null
  }> {}

export interface OrderNote
  extends ImmutableMap<{
    id: number
    note: string
    orderId: OrderIdType
    type: string
    createdAt: ISODateTimeString
    updatedAt: ISODateTimeString
    updatedAtEpoch: number
  }> {}

export type TextIdType = number

export interface ServiceMatrixTextProps {
  dk: string
  en: string
  fi: string
  fr: string | null
  nb: string
  sv: string
  groupKey: string
  id: TextIdType
  name: string
}

export interface ServiceMatrixText extends ImmutableMap<ServiceMatrixTextProps> {}

export interface ServiceMapping {
  id: ServiceMatrixIdType
  customerAlystraId: CustomerAlystraId
  pickuptimeinsecs: Second
  timeWindowInMinutes: Minute
  deliverytimeinsecs: Second
  serviceCode: string
  serviceName: string
  allowFlexDelivery: boolean
  internalNameText: string
  internalDescriptionText: string
  externalNameText: string
  externalDescriptionText: string
  serviceMappingType: ServiceMappingType
  stopsBeforeShowEta: number | null
  pickupTimeSubsequentShipments?: Second
  deliveryTimeSubsequentShipments?: Second
  minutesUntilDelivery: number | null
  sendAutomaticEtaSms: boolean | null
  serviceDeliveryType: ServiceDeliveryType | null
  allowOffered: boolean
}

export type ServiceMappingType = 'service' | 'vas'

export interface ServiceMatrixData extends ImmutableMap<ServiceMapping> {}

export interface Slot
  extends ImmutableMap<{
    id: SlotIdType
    createdBy: string
    createdByName?: string
    courierId: UnitIdType
    unitName?: string
    unitId: UnitIdType
    state: string
    manuallyRoutePlan: boolean
    timeFrom: string
    timeTo: string
    tzTimeFrom: string
    tzTimeTo: string
    color: string
    name: string
    metaPlannedTimeSec: number
    metaPlannedDistanceMeter: number
    metaFinishedConsignments: number
    metaCorrectlyFinishedConsignments: number
    metaWaitingTimeSec: number
    delay: number
    type: string
    departmentId: number
    deptName: string
    custId: number
    driverReturnToAddress: boolean
    startLocationLat: number
    startLocationLng: number
    endLocationLat: number
    endLocationLng: number
    planningCompleted: boolean
    userId?: CourierUserId
    metaNumberOfTrips?: number
    area?: List<Coordinates>
    producedInCorrectOrder: boolean | null
    locked?: boolean
    vehicleId: number
  }> {}

export interface Coordinates
  extends ImmutableMap<{
    lat: number
    lng: number
  }> {}

export interface SlotProgress
  extends ImmutableMap<{
    id: SlotIdType
    states: List<SlotProgressState>
  }> {}

export interface SlotProgressState
  extends ImmutableMap<{
    orderId: OrderIdType
    state: ConsignmentState
  }> {}

export interface VehicleProps {
  id: VehicleIdType
  userId: number
  updatedAt: string
  updatedBy: string
  createdAt: string
  createdBy: string
  capacity?: number
  speedFactor?: number
  serviceTimeFactor: number
  startLocationLat: number
  startLocationLng: number
  endLocationLat?: number
  endLocationLng?: number
  driverReturnToAddress: boolean
  name: string
  isActive: boolean
  vehicleType: string
  departments: List<DepartmentIdType>
  fuelType: string
  fuelCategory: string
  volumeCapacity: number
  weightCapacity: number
  color?: string
  maxRangeKm?: number
  registrationNumber?: string
  commercialTrafficEligibility?: boolean
  vehicleBrand?: string
  vehicleModel?: string
  vehicleControl?: string
  vehicleControlComment?: string
  euroClass?: string
  co2?: string
  break?: Map<string, string>
  minShipmentWeightKg?: number
  maxShipmentWeightKg?: number
}

export interface Vehicle extends ImmutableMap<VehicleProps> {}

export interface UnitProps {
  id: UnitIdType
  userId: number
  updatedAt: string
  updatedBy: string
  createdAt: string
  createdBy: string
  name: string
  alystraId: string
  isActive: boolean
  vehicleType: string
  driverReturnToAddress: boolean
  departments: List<DepartmentIdType>
  startLocationLat: number
  startLocationLng: number
  endLocationLat: number
  endLocationLng: number
  serviceTimeFactor: number
  speedFactor: number
  capacity: number
  fuelType: string
  fuelCategory: string
  volumeCapacity: number
  weightCapacity: number
  color: string
  location?: CourierLocation
  latestLogin?: string
  latestBuild?: number
  maxRangeKm: number
  registrationNumber: string
  commercialTrafficEligibility?: boolean
  vehicleBrand: string
  vehicleModel: string
  vehicleControl: string
  vehicleControlComment: string
  euroClass: string
  co2: string
  userIds?: List<CourierUserId>
  users?: Map<number, CourierUser>
  break: Map<string, string>
}

export interface Unit extends ImmutableMap<UnitProps> {}

type UnitWithoutUsersProps = Omit<UnitProps, 'users'>

export interface UnitWithoutUsers extends ImmutableMap<UnitWithoutUsersProps> {}

export const courierPhoneNumber = (unit: Unit) => {
  const courierUsers = unit.get('userIds')

  return courierUsers && courierUsers.size > 1
    ? i18next.t('courier.multipleDrivers')
    : unit.get('users')?.first(null)?.get('phoneNumber')
}

export const courierDescription = (unit: Unit) => {
  const courierUsers = unit.get('userIds')

  if (courierUsers && courierUsers.size > 1) {
    return i18next.t('courier.multipleDrivers')
  } else {
    const firstUser = unit.get('users')?.first(undefined)
    return `${firstUser?.get('name', '')}, ${firstUser?.get('phoneNumber')}`
  }
}

export type CourierUserId = number

export interface CourierUserProps {
  id: CourierUserId
  name: string
  canLogIn: boolean
  secureLoginEnabled: boolean
  phoneNumber: string
  driverId: string
  unitIds: List<number>
  //device info
  lastLogin: string
  deviceBrand: string
  deviceModel: string
  deviceOs: string
  appBuild: number
  alcoholDeliveryEducation: boolean
  departments: List<DepartmentIdType>
  email: string | null
  dateOfBirth: DateTime | null
  occupationalInjuryInsurance: boolean | null
  courierScanEnabled: boolean
  role: string
}

export interface CourierUser extends ImmutableMap<CourierUserProps> {}

export interface CourierLocationProps {
  id: CourierLocationIdType
  updatedAt: string
  courierId: UnitIdType
  userId: number
  departmentId: DepartmentIdType
  departmentIds?: List<DepartmentIdType>
  custId: CustomerIdType
  customerIds: List<CustomerIdType>
  lat: number
  lng: number
  //optional fields for map markers
  userName?: string
  userPhoneNumber?: string
}

export interface CourierLocation extends ImmutableMap<CourierLocationProps> {}

export interface OrderCorrectionProps {
  id: number
  accepted: boolean
  createdBy: string
  deliveryWaitingTimeInMinutes: number
  numberOfPackages: number
  orderId: number
  pickupWaitingTimeInMinutes: number
  volumeUnit: string
  volumeValue: number
  weightUnit: string
  weightValue: number
  userName: number
  departmentId: DepartmentIdType
  deliveryLoadingTimeInMinutes: number
  pickupLoadingTimeInMinutes: number
  comment: string
}

export type RoleId = number
export interface RoleProps {
  id: RoleId
  name: string
  icon?: IconName
  description?: string
  access: List<UserAccessKey>
  descriptionI18nKey?: string
  nameI18nKey?: string
  defaultForUserRole?: string
}

export interface SessionUser extends ImmutableMap<UserBase> {}
export interface Role extends ImmutableMap<RoleProps> {}

export interface UserProps extends Omit<UserBase, 'customerIds' | 'role' | 'access'> {
  departments?: List<DepartmentIdType>
  customerIds?: List<CustomerIdType>
  roleIds?: List<RoleId>
  role: string
}
export interface AirExpressUserProps extends UserProps {
  airlineTerminalIds?: List<number>
}

export interface AirExpressUser extends ImmutableMap<AirExpressUserProps> {}

export interface User extends ImmutableMap<UserProps> {}

export interface AdminCustomerEmailProps {
  id: number
  emailId: string
  createdEvent: boolean
  collectedEvent: boolean
  deliveredEvent: boolean
  returnToSender: boolean
  reminderToSender: boolean
  claims: boolean
}

export interface AdminCustomerDeviationProps {
  id: number
  headerEn: string
  deviationCode: string
}

export interface CustomMeasurementProps {
  id: number
  customerId: CustomerIdType
  name: string
  presetDimension: string
  weight: number
  height: number
  length: number
  width: number
}

export interface CustomMeasurement extends ImmutableMap<CustomMeasurementProps> {}

export interface PlannerProps {
  planner: number | null
  name: string
  email?: string
  phoneNumber: string
  departments?: number[]
  customers?: number[]
  roleType: string
  canLogIn: boolean
  roles?: RoleId[]
}

export type Optimizing = ImmutableMap<{
  id: number
  containErrorResponse: boolean
  containRequest: boolean
  containResponse: boolean
  courierId: number
  createdAt: string
  departmentname: string
  graphhopperId: string
  requestorname: string
  status: string
  type: string
  updatedAt: string
  requestByteSize: number
  consignmentIds: Set<number> | null
  numberOfVehicles: number
  processingTime: number
}>

export type ApolloAuditLog = ImmutableMap<{
  id: number
  departmentId: DepartmentIdType
  departmentName: string
  request: AnyData
  response: AnyData
  routeId: number
  routeName: string
  status: string
  timestamp: string
  type: string
  userId: number
  userName: string | null
}>

export interface TemplateProps {
  id: number
  departmentId: DepartmentIdType
  name: string
}

export type Template = ImmutableMap<TemplateProps>

export interface TemplateSlotProps {
  templateId: number
}

export type TemplateSlot = ImmutableMap<TemplateSlotProps>

export type DepartmentGroupIdType = number

export type CustomerGroupIdType = number

export interface DepartmentGroupProps {
  id: DepartmentGroupIdType
  name: string
  departments: List<DepartmentIdType>
  description?: string
}
export interface DepartmentGroupPropsJS {
  id: DepartmentGroupIdType
  name: string
  departments: Array<DepartmentIdType>
  description?: string
}

export interface DepartmentGroup extends ImmutableMap<DepartmentGroupProps> {}

export interface CustomerGroupProps {
  id: CustomerGroupIdType
  name: string
  customers: List<CustomerIdType>
  description?: string
  usedInPreadvice: boolean
}

export interface CustomerGroup extends ImmutableMap<CustomerGroupProps> {}

export interface ConsignmentEventLM
  extends ImmutableMap<{
    id: ConsignmentEventId
    consignmentId: ConsignmentIdType
    type: EventType
    eventTime: ISODateTimeString
    data?: AnyData
    packageId?: string
    shipmentId?: string
    orderId?: OrderIdType
    syncJobId?: number
    lmPayload?: AnyData
    sentToLmAt?: ISODateTimeString
  }> {}

export type PreAdvicePolicyIdType = number

export type PreAdvicePolicyRowIdType = number

export interface PreAdvicePolicyProps {
  id: PreAdvicePolicyIdType
  name: string
  servicePolicies: List<PreAdvicePolicyRowIdType>
  departmentGroups: List<DepartmentGroupIdType>
  customerGroups: List<CustomerGroupIdType>
  customers: List<CustomerIdType>
  departments: List<DepartmentIdType>
  description?: string
}
export interface PreAdvicePolicyPropsJS {
  id: PreAdvicePolicyIdType
  name: string
  servicePolicies: Array<PreAdvicePolicyRowIdType>
  departmentGroups: Array<DepartmentGroupIdType>
  customerGroups: Array<CustomerGroupIdType>
  customers: Array<CustomerIdType>
  departments: Array<DepartmentIdType>
  description?: string
}
type ServicePolicyIdType = string

export interface PreAdviceServicePolicyProps {
  id: ServicePolicyIdType | null
  serviceCode: string
  vasCode?: string
  sameDay: boolean
  triggerEvent: string
  cutoff: string
  relativeCutoffMinutes?: string
  calendarDays: number
  returnToSenderAfterDays?: number
  scheme: string
  reminderSmsHours?: number
  preAdviceAttempts?: number
  reminderSmsToSenderHours?: number
  returnToPup?: boolean
  enableCancelBookingTimes?: boolean
  deliveryAttempts?: number
  prebooked?: boolean
}

export type ExportDataType =
  | 'SHIPMENT_EXPORT'
  | 'COURIER_WORK_REPORT'
  | 'COURIER_WORK_REPORT_EXTENDED'
  | 'END_OF_DAY_REPORT'
  | 'LOCATION_LIST'
  | 'SOCIAL_CONTROL'
  | 'ORDER_EXPORT'
  | 'ORDER_EXPORT_ANONYMOUS'
  | 'ORDER_DEVIATION_EXPORT'
  | 'ORDER_PARTNER_EXPORT'
  | 'DEVIATION_EVENTS'
  | 'AIREXPRESS_SHIPMENTS'

export interface ExportDataExecution
  extends ImmutableMap<{
    id: number
    createdAt: string
    updatedAt: string
    createdBy: string
    startedAt: string | null
    departmentId: DepartmentIdType | undefined
    departmentGroupId: DepartmentGroupIdType | undefined
    errorMessage: string | undefined
    failed: boolean
    finished: boolean
    finishedDays: number
    totalDays: number
    fromDate: string
    toDate: string
    name: string
    type: ExportDataType
    updatedByName: string
  }> {}

export interface ImportDataExecution
  extends ImmutableMap<{
    id: number
    createdAt: string
    departmentId: DepartmentIdType
    importId: string
    importedByName?: string
    fileName: string
    numberOfOrders: number
  }> {}

export interface PreAdviceStatusProps {
  consignmentId: number
  orderId: number
  preAdviceStatus: string
  hasPreAdviceSetting: boolean
}

export interface PreAdviceStatus extends ImmutableMap<PreAdviceStatusProps> {}

export interface DeliveryMetaDataProps {
  id: DeliveryMetaDataId
  consignmentId: ConsignmentIdType
  messageText?: string
  textId?: TextIdType
  lockCode?: string
  housingInformation?: HousingInformation
}

export interface DeliveryMetaData extends ImmutableMap<DeliveryMetaDataProps> {}

export interface InvoicingStatusCountProps {
  departmentId: DepartmentIdType
  fromDate: string
  toDate: string
  toBeInvoiced: number
  invoicingFailed: number
  correctionPending: number
  invoicingPaused: number
  zeroPrice: number
}

export interface InvoicingStatusCount extends ImmutableMap<InvoicingStatusCountProps> {}

export interface InvoiceItemProps {
  id: number
  orderId: OrderIdType
  createdAt: Date | null
  articleCode: string | null
  specification: string
  quantity: number | string | null
  customerAmount: number | string | null
  carrierAmount: number | string | null
  lineId: string | null
  unitId: UnitIdType | null
}

export interface InvoiceItem extends ImmutableMap<InvoiceItemProps> {}

export type PreAdviceStatusType =
  | 'ERROR'
  | 'NO_DELIVERY_TIME_WINDOW'
  | 'NO_VALID_PHONE_OR_EMAIL'
  | 'WAITING_FOR_PACKAGES'
  | 'NOT_STARTED'
  | 'IN_PROGRESS'
  | 'FINISHED'
  | 'PRE_ADVICE_LOCKED'

export type PreAdviceType = 'PULL' | 'PUP' | 'PUSH' | 'RETURN'

export interface PreAdviceProps {
  arrivedAtHub: boolean | null
  consignmentId: ConsignmentIdType
  departmentId: DepartmentIdType
  failed: boolean
  hasPreAdviceSetting: boolean
  orderId: OrderIdType
  preAdviceScheduledDate: ISODateString
  preAdviceStatus: PreAdviceStatusType
  preAdvices: number
  returnToSender: boolean
  type: PreAdviceType | null
}

export interface PreAdvice extends ImmutableMap<PreAdviceProps> {}

export interface MultistopDetailProps {
  orderId: OrderIdType
  stopOrder: number
  groupId: string
  shipmentId: string
  deliveryTime?: ISODateTimeString
  stopType: 'delivery' | 'pickup'
  departmentId: DepartmentIdType
  stopAddress?: string
  stopZipCode?: string
  stopZipArea?: string
  stopCountry?: string
}

export interface MultistopDetail extends ImmutableMap<MultistopDetailProps> {}
