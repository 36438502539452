import {
  ASSIGN_COURIER_TO_DEPARTMENT,
  ASSIGN_COURIER_TO_UNIT,
  ASSIGN_UNIT_TO_DEPARTMENT,
  CLEAR_SEARCH_COURIER_USERS,
  CLEAR_SEARCH_UNITS,
  CREATE_COURIER_USER,
  GET_COURIER_USER_BY_ID,
  GET_COURIER_USERS_FOR_DEPARTMENT,
  GET_COURIERS_FOR_UNIT,
  REMOVE_FROM_UNIT_SEARCH,
  SEARCH_COURIER_USERS,
  SEARCH_UNITS,
  UNASSIGN_COURIER_FROM_UNIT,
  UNASSIGN_UNIT_FROM_DEPARTMENT,
  UPDATE_COURIER_INFORMATION,
  UPDATE_COURIER_USER
} from '../../actions/actionTypes'
import { api } from '../../http/httpHelper'
import { fetchIfNotPresent, ThunkDispatch } from '../../actions/creators/baseHelpers'
import { ImmutableMap } from '../../types/immutableTypes'
import { NewCourierUserProps } from './CreateCourierUserForm'
import { AppStateType } from '../../utils/appStateReduxStore'
import { List, Map } from 'immutable'
import { CourierUserWithActionProps } from './CourierUserGrid'
import { createImmutableEqualSelector } from '../../selectors/createImmutableEqualSelector'
import { UnitFormProps } from './UnitForm'
import { BreakType, getUnitPayload, UnitPayload } from '../../actions/creators/helpers'
import { CourierUser, CourierUserId, CourierUserProps, DepartmentIdType } from '../../types/coreEntitiesTypes'
import { DateTime, Duration } from 'luxon'
import { Option } from '@glow/ui-components'
import i18next from 'i18next'

export const driveTimeHours: Option[] = [4, 5, 6, 7, 8, 9, 10, 11, 12].map((hours) => ({
  code: hours.toString(),
  label: i18next.t('courier.hours', { hours })
}))

export interface LatestAppBuild {
  ios: string
  android: string
}

export const clearSearchUnitCouriers = () => (dispatch: ThunkDispatch) => {
  return dispatch({
    type: CLEAR_SEARCH_COURIER_USERS
  })
}

export const clearSearchUnits = () => (dispatch: ThunkDispatch) => {
  return dispatch({
    type: CLEAR_SEARCH_UNITS
  })
}

export const removeFromUnitSearch = (unitId: number) => (dispatch: ThunkDispatch) => {
  return dispatch({
    type: REMOVE_FROM_UNIT_SEARCH,
    unitId
  })
}

export const assignCourierToUnit = (userId: number, unitId: number) => (dispatch: ThunkDispatch) =>
  dispatch(
    api.asyncCommand(ASSIGN_COURIER_TO_UNIT, {
      type: ASSIGN_COURIER_TO_UNIT.name,
      payload: {
        userId,
        unitId
      }
    })
  )

export const assignUnitToDepartment = (unitId: number, departmentId: number) => (dispatch: ThunkDispatch) =>
  dispatch(
    api.asyncCommand(ASSIGN_UNIT_TO_DEPARTMENT, {
      type: ASSIGN_UNIT_TO_DEPARTMENT.name,
      payload: {
        unitId,
        departmentId
      }
    })
  )

export const unassignUnitFromDepartment = (unitId: number, departmentId: number) => (dispatch: ThunkDispatch) =>
  dispatch(
    api.asyncCommand(UNASSIGN_UNIT_FROM_DEPARTMENT, {
      type: UNASSIGN_UNIT_FROM_DEPARTMENT.name,
      payload: {
        unitId,
        departmentId
      }
    })
  )

export const assignCourierToDepartment = (courierId: number, departmentId: number) => (dispatch: ThunkDispatch) =>
  dispatch(
    api.asyncCommand(ASSIGN_COURIER_TO_DEPARTMENT, {
      type: ASSIGN_COURIER_TO_DEPARTMENT.name,
      payload: {
        userId: courierId,
        departmentId
      }
    })
  )

export const searchCourierUsers = (query: string, excludedDepartmentId?: number | null) => (dispatch: ThunkDispatch) =>
  dispatch(
    api.asyncQuery(SEARCH_COURIER_USERS, {
      query: 'searchCourierUsers',
      params: {
        query,
        excludedDepartmentId
      }
    })
  )

export const searchUnits = (query: string, excludeDepartmentId?: number) => (dispatch: ThunkDispatch) =>
  dispatch(
    api.asyncQuery(SEARCH_UNITS, {
      query: 'searchUnits',
      params: { query, excludeDepartmentId }
    })
  )

export const createNewCourierUser =
  (courier: ImmutableMap<NewCourierUserProps>, departmentId: number, unitId?: number) => (dispatch: ThunkDispatch) =>
    dispatch(
      api.asyncCommand(CREATE_COURIER_USER, {
        type: CREATE_COURIER_USER.name,
        payload: {
          unitId,
          departmentId,
          ...courier.toJS()
        }
      })
    )

export const unassignCourierFromUnit = (userId: number, unitId: number) => (dispatch: ThunkDispatch) =>
  dispatch(
    api.asyncCommand(UNASSIGN_COURIER_FROM_UNIT, {
      type: UNASSIGN_COURIER_FROM_UNIT.name,
      payload: {
        userId,
        unitId
      }
    })
  )

export const getCouriersForUnit = (unitId: number) => (dispatch: ThunkDispatch) =>
  dispatch(
    fetchIfNotPresent(GET_COURIERS_FOR_UNIT, {
      query: 'getCourierUsers',
      params: { unitId }
    })
  )

export const getCourierUsersForDepartment = (departmentId: DepartmentIdType) => (dispatch: ThunkDispatch) =>
  dispatch(
    fetchIfNotPresent(GET_COURIER_USERS_FOR_DEPARTMENT, {
      query: 'getCourierUsersForDepartment',
      params: { departmentId }
    })
  )

export const getCourierUserById = (userId: CourierUserId) => (dispatch: ThunkDispatch) =>
  dispatch(
    fetchIfNotPresent(GET_COURIER_USER_BY_ID, {
      query: 'getCourierUserById',
      params: { userId }
    })
  )

const usersAsCourierUsersSelector: (state: AppStateType) => Map<CourierUserId, CourierUser> =
  createImmutableEqualSelector(
    (state: AppStateType) => state.getIn(['entities', 'users']),
    (users: Map<CourierUserId, CourierUser>): Map<number, CourierUser> => (users ? users : Map())
  )

export const courierUsersForDepartmentSelector: (
  state: AppStateType,
  departmentId: DepartmentIdType
) => Map<CourierUserId, CourierUser> = createImmutableEqualSelector(
  usersAsCourierUsersSelector,
  (state: AppStateType, departmentId: DepartmentIdType) => departmentId,
  (users: Map<CourierUserId, CourierUser>, departmentId: DepartmentIdType) =>
    (users || Map()).filter((u) => u.get('role') == 'courier' && u.get('departments').contains(departmentId))
)

export const courierUsersSelector: (state: AppStateType) => Map<CourierUserId, CourierUser> =
  createImmutableEqualSelector(usersAsCourierUsersSelector, (users: Map<CourierUserId, CourierUser>) =>
    (users || Map()).filter((u) => u.get('role') == 'courier')
  )

export const courierUsersByUnitIdSelector: (
  state: AppStateType,
  unitId: number
) => Map<CourierUserId, ImmutableMap<CourierUserWithActionProps>> = createImmutableEqualSelector(
  courierUsersSelector,
  (state: AppStateType, unitId: number) => unitId,
  (users: Map<CourierUserId, CourierUser>, unitId: number) =>
    users.filter((u) => (u.get('unitIds') || List()).contains(unitId)) as Map<
      CourierUserId,
      ImmutableMap<CourierUserWithActionProps>
    >
)

const activeCourierUsersByUnitIdSelector: (
  state: AppStateType,
  unitId: number
) => Map<CourierUserId, ImmutableMap<CourierUserWithActionProps>> = createImmutableEqualSelector(
  courierUsersSelector,
  (state: AppStateType, unitId: number) => unitId,
  (users: Map<CourierUserId, CourierUser>, unitId: number) =>
    users.filter((u) => u.get('canLogIn') && (u.get('unitIds') || List()).contains(unitId)) as Map<
      CourierUserId,
      ImmutableMap<CourierUserWithActionProps>
    >
)

const courierUsersByUnitIdSelectorList: (
  state: AppStateType,
  unitId: number
) => List<ImmutableMap<CourierUserWithActionProps>> = createImmutableEqualSelector(
  activeCourierUsersByUnitIdSelector,
  (users: Map<CourierUserId, ImmutableMap<CourierUserWithActionProps>>) => users.toList()
)

export const courierUsersWithAlcoholDeliveryEducationFilterByUnitIdSelectorList: (
  state: AppStateType,
  unitId: number,
  requireAlcoholDeliveryEducation: boolean
) => List<ImmutableMap<CourierUserWithActionProps>> = createImmutableEqualSelector(
  courierUsersByUnitIdSelectorList,
  (state: AppStateType, unitId: number, requireAlcoholDeliveryEducation: boolean) => requireAlcoholDeliveryEducation,
  (users: List<ImmutableMap<CourierUserWithActionProps>>, requireAlcoholDeliveryEducation: boolean) =>
    users.toList().filter((u) => (requireAlcoholDeliveryEducation ? u.get('alcoholDeliveryEducation') : true))
)

export const courierUsersByDepartmentSelector: (
  state: AppStateType,
  departmentId: DepartmentIdType
) => Map<CourierUserId, ImmutableMap<CourierUserProps>> = createImmutableEqualSelector(
  courierUsersSelector,
  (state: AppStateType, departmentId: DepartmentIdType) => departmentId,
  (users: Map<CourierUserId, ImmutableMap<CourierUserProps>>, departmentId: DepartmentIdType) =>
    users.filter((u) => (u.get('departments') || List()).contains(departmentId))
)

export const courierUsersByDepartmentIdsSelector: (
  state: AppStateType,
  departmentIds: List<DepartmentIdType>
) => Map<CourierUserId, CourierUser> = createImmutableEqualSelector(
  courierUsersSelector,
  (state: AppStateType, departmentIds: List<DepartmentIdType>) => departmentIds,
  (users: Map<CourierUserId, CourierUser>, departmentIds: List<DepartmentIdType>) =>
    users.filter((u) => !(u.get('departments') || List()).toSet().intersect(departmentIds.toSet()).isEmpty())
)

export const courierUserByIdSelector: (state: AppStateType, userId: number) => CourierUser =
  createImmutableEqualSelector(
    courierUsersSelector,
    (state: AppStateType, userId: number) => userId,
    (users: Map<CourierUserId, CourierUser>, userId: number) => users.get(userId) || Map()
  )

export const latestAppBuildsFromCouriersSelector: (
  state: AppStateType,
  departmentId: DepartmentIdType
) => LatestAppBuild = createImmutableEqualSelector(
  courierUsersByDepartmentSelector,
  (courierUsers: Map<CourierUserId, CourierUser>) => {
    const latestAppBuild = (os: string): string =>
      courierUsers
        .valueSeq()
        .filter((it) => it?.get('deviceOs')?.toLowerCase() === os)
        .sortBy((it) => it.get('appBuild'))
        .last<CourierUser>()
        ?.get('appBuild')
        ?.toString() || 'N/A'

    return {
      ios: latestAppBuild('ios'),
      android: latestAppBuild('android')
    }
  }
)

export const updateUnit2 = (payload: UnitPayload) =>
  api.asyncCommand(UPDATE_COURIER_INFORMATION, {
    type: UPDATE_COURIER_INFORMATION.name,
    payload
  })

export const updateUnit = (values: ImmutableMap<UnitFormProps>, departmentId: number) => (dispatch: ThunkDispatch) =>
  dispatch(updateUnit2(getUnitPayload(values, departmentId)))

export const updateCourierUser =
  (
    id: number,
    name: string,
    phoneNumber: string,
    canLogIn: boolean,
    secureLoginEnabled: boolean,
    alcoholDeliveryEducation: boolean,
    driverId: string,
    email: string | null,
    dateOfBirth: DateTime | string | null,
    occupationalInjuryInsurance: boolean | null,
    courierScanEnabled: boolean
  ) =>
  (dispatch: ThunkDispatch) =>
    dispatch(
      api.asyncCommand(UPDATE_COURIER_USER, {
        type: UPDATE_COURIER_USER.name,
        payload: {
          id,
          name,
          phoneNumber,
          canLogIn,
          secureLoginEnabled,
          alcoholDeliveryEducation,
          driverId,
          email,
          dateOfBirth,
          occupationalInjuryInsurance,
          courierScanEnabled
        }
      })
    )

export const getBreakDuration = (courierBreak: Map<string, string>, breakType: BreakType) =>
  courierBreak?.get('type') === breakType
    ? Duration.fromISO(courierBreak.get('duration') || '')
        .as('minutes')
        .toString()
    : ''
