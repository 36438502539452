import { icon, LatLng, LatLngExpression } from 'leaflet'
import React from 'react'
import { Marker } from 'react-leaflet'

import { SlotDataInterface } from '../../pages/planner/route-management/RouteManagementUtils'
import { WayPoint } from '../../query/liveViewQuery'
import { OrderIdType, Shipment } from '../../types/coreEntitiesTypes'
import { getMapMarkerFromConsignmentState, MarkerVariant } from '../map-base/mapUtils'
import DeliveredEnabled from './icons/delivery-delivered-enabled.svg'
import DeliveredSelected from './icons/delivery-delivered-selected.svg'
import DeviatedEnabled from './icons/delivery-deviation-enabled.svg'
import DeviatedSelected from './icons/delivery-deviation-selected.svg'
import DeliveryEnabled from './icons/delivery-enabled.svg'
import ReturnedEnabled from './icons/delivery-returned-enabled.svg'
import ReturnedSelected from './icons/delivery-returned-selected.svg'
import DeliverySelected from './icons/delivery-selected.svg'
import PickupEnabled from './icons/pickup-enabled.svg'
import PickupSelected from './icons/pickup-selected.svg'
import SlotDetailsEndPosition from './icons/slot-details-end-point.svg'
import SlotDetailsStartPosition from './icons/slot-details-start-point.svg'

export const ShipmentMarker = (
  shipment: Shipment,
  selected?: boolean,
  onInDragMode?: (inDragMode: boolean) => void,
  onDrop?: (orderId: OrderIdType, position: LatLng) => void,
  onMarkerClick?: (shipment: Shipment) => void,
  draggable?: boolean
) => {
  return [
    <Marker
      key={shipment.get('id') + '-pickup'}
      position={{ lat: shipment.get('pickupLat'), lng: shipment.get('pickupLng') }}
      eventHandlers={{
        click: () => onMarkerClick?.(shipment),
        dragstart: () => onInDragMode?.(true),
        dragend: (event: { target: { getLatLng: () => LatLng | undefined; setLatLng: (value: LatLng) => void } }) => {
          const newPosition = event.target.getLatLng()
          onInDragMode?.(false)
          if (newPosition) {
            const previousPosition = new LatLng(shipment.get('pickupLat'), shipment.get('pickupLng'))
            event.target.setLatLng(previousPosition)
            onDrop?.(shipment.get('id'), newPosition)
          }
        }
      }}
      draggable={draggable}
      icon={icon({
        iconUrl: markerVariantUrl('pickup', selected ?? false),
        iconSize: [24, 24],
        iconAnchor: selected ? [0, 0] : [12, 12]
      })}
    />,
    <Marker
      key={shipment.get('id') + '-delivery'}
      eventHandlers={{
        click: () => onMarkerClick?.(shipment),
        dragstart: () => onInDragMode?.(true),
        dragend: (event: { target: { getLatLng: () => LatLng | undefined; setLatLng: (value: LatLng) => void } }) => {
          const newPosition = event.target.getLatLng()
          onInDragMode?.(false)
          if (newPosition) {
            const previousPosition = new LatLng(shipment.get('deliveryLat'), shipment.get('deliveryLng'))
            event.target.setLatLng(previousPosition)
            onDrop?.(shipment.get('id'), newPosition)
          }
        }
      }}
      position={{ lat: shipment.get('deliveryLat'), lng: shipment.get('deliveryLng') }}
      draggable={draggable}
      icon={icon({
        iconUrl: markerVariantUrl(getMapMarkerFromConsignmentState(shipment.get('state')), selected ?? false),
        iconSize: [24, 24],
        iconAnchor: selected ? [0, 0] : [12, 12]
      })}
    />
  ]
}
export const WayPointMarker = (wayPoint: WayPoint, onMarkerClick?: (wayPoint: WayPoint) => void) => {
  if (wayPoint.lat && wayPoint.lng) {
    const markerVariant = wayPoint.type == 'delivery' ? getMapMarkerFromConsignmentState(wayPoint.state) : 'pickup'
    return (
      <Marker
        key={wayPoint.id}
        position={{ lat: wayPoint.lat, lng: wayPoint.lng }}
        icon={icon({
          iconUrl: markerVariantUrl(markerVariant, true),
          iconSize: [24, 24],
          iconAnchor: [12, 12]
        })}
        eventHandlers={{
          click: () => (onMarkerClick ? onMarkerClick(wayPoint) : () => {})
        }}
      />
    )
  }
  return null
}

export const SlotDetailsStartEndPointMarker = (slot: SlotDataInterface) => {
  const startPosition = { lat: slot.get('startLocationLat'), lng: slot.get('startLocationLng') }
  const endPosition = { lat: slot.get('endLocationLat'), lng: slot.get('endLocationLng') }
  const markers: JSX.Element[] = []
  if (startPosition.lat && startPosition.lng) {
    markers.push(
      <Marker
        key={slot.get('id') + 'start'}
        position={startPosition as LatLngExpression}
        icon={icon({ iconUrl: SlotDetailsStartPosition, iconSize: [20, 20], iconAnchor: [10, 10] })}
      />
    )
  }
  if (endPosition.lat && endPosition.lng) {
    markers.push(
      <Marker
        key={slot.get('id') + 'end'}
        position={endPosition as LatLngExpression}
        icon={icon({ iconUrl: SlotDetailsEndPosition, iconSize: [20, 20], iconAnchor: [10, 10] })}
      />
    )
  }
  return markers.length > 0 ? markers : null
}

function markerVariantUrl(variant: MarkerVariant, selected: boolean): string {
  switch (variant) {
    case 'pickup':
      return selected ? PickupSelected : PickupEnabled
    case 'delivered':
      return selected ? DeliveredSelected : DeliveredEnabled
    case 'deviated':
      return selected ? DeviatedSelected : DeviatedEnabled
    case 'returned':
      return selected ? ReturnedSelected : ReturnedEnabled
    case 'delivery':
    default:
      return selected ? DeliverySelected : DeliveryEnabled
  }
}
